import TCOMath from "../TCOMath"
import { hasPowerExchange } from "../Utility.js"
class GraphsBuilder {
  constructor(t, constants, isAuth, advancedConfig, selectedUPS, kwhPrice, opYears, cop, inflation) {
    this.t = t
    this.constants = constants
    this.isAuth = isAuth
    this.advancedConfig = advancedConfig
    this.selectedUPS = selectedUPS
    this.kwhPrice = TCOMath.normalize(kwhPrice, parseFloat)
    this.opYears = TCOMath.normalize(opYears, parseInt)
    this.cop = TCOMath.normalize(cop, parseInt)
    this.inflation = TCOMath.normalize(inflation, parseFloat)
    this.init()
  }

  init() {
    this.tcoList = []
    // handle translation
    this.productLabel = this.t("graphs_labels.ups_cost")
    this.lossesLabel = this.t("graphs_labels.losses")
    this.contractLabel = this.t("graphs_labels.contract")
    this.batteryLabel = this.t("graphs_labels.battery")
    this.consumablesLabel = this.t("select_ups.consumables")
    this.upsLabel = this.t("general.ups")
    this.sparePartsLabel = this.t("select_ups.spare_parts_list")
    this.powerExchangeLabel = this.t("select_ups.power_exchange")

    for (let upsIndex = 0; upsIndex < this.selectedUPS.length; upsIndex++) {
      this.tcoList[upsIndex] = TCOMath.solveTCOCost(
        this.selectedUPS[upsIndex],
        upsIndex,
        this.kwhPrice,
        this.opYears,
        this.cop,
        this.inflation,
        this.isAuth,
        this.advancedConfig
      )
    }
  }

  buildTotalLosses() {
    const labels = this.selectedUPS.map((ups) => ups.name)
    const data = {
      labels,
      datasets: [
        {
          data: labels.map((label, labelIndex) => this.tcoList[labelIndex].losses.total),
          backgroundColor: this.constants.graphsComponents.losses,
        },
      ],
    }

    return data
  }

  buildTotalCosts() {
    const labels = this.selectedUPS.map((ups) => ups.name)
    // prepare data
    const costs = [
      {
        label: this.productLabel,
        data: labels.map((label, labelIndex) => this.tcoList[labelIndex].product),
        backgroundColor: this.constants.graphsTCOColors[0],
      },
      {
        label: this.lossesLabel,
        data: labels.map((label, labelIndex) => this.tcoList[labelIndex].losses.total),
        backgroundColor: this.constants.graphsTCOColors[1],
      },
    ]

    if (this.advancedConfig) {
      costs.push(
        {
          label: this.contractLabel,
          data: labels.map((label, labelIndex) => this.tcoList[labelIndex].contract.total),
          backgroundColor: this.constants.graphsTCOColors[2],
        },
        {
          label: this.batteryLabel,
          data: labels.map((label, labelIndex) => this.tcoList[labelIndex].battery.total),
          backgroundColor: this.constants.graphsTCOColors[3],
        }
      )
    }

    if (this.isAuth && this.advancedConfig) {
      costs.push(
        {
          label: this.consumablesLabel,
          data: labels.map((label, labelIndex) => this.tcoList[labelIndex].consumables),
          backgroundColor: this.constants.graphsTCOColors[4],
        },
        {
          label: this.sparePartsLabel,
          data: labels.map((label, labelIndex) => this.tcoList[labelIndex].spareParts.total),
          backgroundColor: this.constants.graphsTCOColors[5],
        }
      )
    }

    const data = {
      labels,
      datasets: costs,
    }

    return data
  }

  buildCumulativeCosts() {
    const labels = [...Array(this.opYears + 1).keys()]

    // prepare data
    const cumulCosts = []

    for (let upsIndex = 0; upsIndex < this.selectedUPS.length; upsIndex++) {
      cumulCosts.push(
        {
          label: this.productLabel,
          xAxisID: "x",
          data: [this.tcoList[upsIndex].product],
          backgroundColor: this.constants.graphsTCOColors[0],
          stack: `Stack ${upsIndex}`,
        },
        {
          label: this.lossesLabel,
          xAxisID: "x",
          data: this.tcoList[upsIndex].losses.data,
          backgroundColor: this.constants.graphsTCOColors[1],
          stack: `Stack ${upsIndex}`,
        }
      )

      if (this.advancedConfig) {
        cumulCosts.push(
          {
            label: this.contractLabel,
            xAxisID: "x",
            data: this.tcoList[upsIndex].contract.data,
            backgroundColor: this.constants.graphsTCOColors[2],
            stack: `Stack ${upsIndex}`,
          },
          {
            label: this.batteryLabel,
            xAxisID: "x",
            data: this.tcoList[upsIndex].battery.data,
            backgroundColor: this.constants.graphsTCOColors[3],
            stack: `Stack ${upsIndex}`,
          }
        )
      }

      if (this.isAuth && this.advancedConfig) {
        cumulCosts.push(
          {
            label: this.consumablesLabel,
            xAxisID: "x",
            data: [this.tcoList[upsIndex].consumables],
            backgroundColor: this.constants.graphsTCOColors[4],
            stack: `Stack ${upsIndex}`,
          },
          {
            label: this.sparePartsLabel,
            xAxisID: "x",
            data: this.tcoList[upsIndex].spareParts.cumulativeTotals,
            backgroundColor: this.constants.graphsTCOColors[5],
            stack: `Stack ${upsIndex}`,
          }
        )

        const filteredUPS = this.selectedUPS.filter((sUps) => hasPowerExchange(sUps))
        if (filteredUPS.length > 0) {
          const powerExchangeUpsData = this.tcoList[upsIndex].powerExchange.data
          const negatedPowerExchangeData = powerExchangeUpsData.map((value) => -value)
          cumulCosts.push({
            label: this.powerExchangeLabel,
            xAxisID: "x",
            data: negatedPowerExchangeData,
            backgroundColor: this.constants.graphsTCOColors[6],
            stack: `Stack ${upsIndex}`,
          })
        }
      }
    }
    const data = {
      labels,
      datasets: cumulCosts,
    }
    return data
  }

  buildTCO(advancedConfig) {
    return this.selectedUPS.map((ups, upsIndex) => {
      const productPercent = Math.round(this.tcoList[upsIndex].productPercent * 100)
      const lossPercent = Math.round(this.tcoList[upsIndex].lossesPercent * 100)

      const labels = [`${this.productLabel}: ${productPercent}%`, `${this.lossesLabel}: ${lossPercent}%`]

      const data = [productPercent, lossPercent]

      const sparePartsLabels = []

      let sparePartsPercent = 0

      if (advancedConfig) {
        const contractPercent = Math.round(this.tcoList[upsIndex].contractsPercent * 100)
        const batteryPercent = Math.round(this.tcoList[upsIndex].batteryPercent * 100)
        labels.push(`${this.contractLabel}: ${contractPercent}%`, `${this.batteryLabel}: ${batteryPercent}%`)
        data.push(contractPercent, batteryPercent)
      }

      if (this.isAuth && advancedConfig) {
        const consumablesPercent = Math.round(this.tcoList[upsIndex].consumablesPercent * 100)
        labels.push(`${this.consumablesLabel}: ${consumablesPercent}%`)
        data.push(consumablesPercent)

        sparePartsPercent = this.tcoList[upsIndex].sparePartsPercent.reduce((partialSum, a) => partialSum + a, 0)
        data.push(sparePartsPercent)
        sparePartsLabels.push(`${this.sparePartsLabel}: ${sparePartsPercent}%`)
      }

      return {
        labels: [...labels, ...sparePartsLabels],
        datasets: [
          {
            data: [...data],
            backgroundColor: this.constants.graphsTCOColors,
            borderColor: this.constants.graphsTCOColors,
            borderWidth: 0,
          },
        ],
      }
    })
  }

  buildCumulCostLine() {
    const labels = [...Array(this.opYears + 1).keys()]
    const cumulCosts = []

    for (let upsIndex = 0; upsIndex < this.selectedUPS.length; upsIndex++) {
      // Sum the cumulative tco
      const cumulativeTCOData = this.tcoList[upsIndex].cumulative.map(Number)
      const tcoCostTotal = []
      for (let totLen = 0; totLen < cumulativeTCOData.length; totLen++) {
        if (totLen === 0) {
          // product + spare parts stock
          tcoCostTotal[0] = cumulativeTCOData[0] + this.tcoList[upsIndex].consumables
        } else {
          tcoCostTotal[totLen] = tcoCostTotal[totLen - 1] + cumulativeTCOData[totLen]
        }
      }

      // Power exchanger subtraction
      const filteredUPS = this.selectedUPS.filter((sUps) => hasPowerExchange(sUps))
      if (filteredUPS.length > 0) {
        const powerExchangeUpsData = this.tcoList[upsIndex].powerExchange.data
        powerExchangeUpsData[0] = 0 // first item is empty
        for (let i = 0; i < tcoCostTotal.length; i++) {
          tcoCostTotal[i] -= powerExchangeUpsData[i]
        }
      }

      cumulCosts.push({
        label: this.selectedUPS[upsIndex].name,
        data: tcoCostTotal,
        borderColor: this.constants.graphsTCOColors[upsIndex],
      })
    }

    const data = {
      labels,
      datasets: cumulCosts,
    }

    return data
  }
}

export default GraphsBuilder
