import PropTypes from "prop-types"
import { useState, useEffect } from "react"

import { useIsAuthenticated } from "@azure/msal-react"
import { useTranslation } from "react-i18next"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js"
import GraphsBuilder from "../util/builders/GraphsBuilder"
import { Bar, Line } from "react-chartjs-2"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Form from "react-bootstrap/Form"
import { Constants } from "../util/Constants"

ChartJS.register(CategoryScale, LinearScale, BarElement, PointElement, LineElement, ArcElement, Title, Tooltip, Legend)

const withoutLegendOpt = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
  },
}

export default function GraphsPanel({
  selectedUPS,
  currency,
  kwhPrice,
  opYears,
  cop,
  inflation,
  advancedConfig,
  setTCOList,
  showTotCosts,
  setShowTotCosts,
  showCumulCosts,
  setShowCumulCosts,
  showTotLosses,
  setShowTotLosses,
  showCumulCostLine,
  setShowCumulCostLine,
}) {
  const [totLossesData, setTotLossesData] = useState({ labels: [], datasets: [] })
  const [totCostsData, setTotCostsData] = useState({ labels: [], datasets: [] })
  const [cumulCostsData, setCumulCostsData] = useState({ labels: [], datasets: [] })

  const [cumulCostLineData, setCumulCostLineData] = useState({ labels: [], datasets: [] })

  const { t, i18n } = useTranslation()
  const isAuth = useIsAuthenticated()

  const withStackedValuesOpt = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          title: (ttItem) => {
            const regex = /Stack (\d+)/
            let match
            if (ttItem[0].dataset.stack) match = ttItem[0].dataset.stack.match(regex)
            if (match && match[1])
              if (selectedUPS && selectedUPS[match[1]] && selectedUPS[parseInt(match[1])].name)
                return `${selectedUPS[parseInt(match[1])].name} \r\n${t("select_ups.spare_product_year")} ${
                  ttItem[0].parsed.x
                }`
            return `${t("select_ups.spare_product_year")} ${ttItem[0].parsed.x}`
          },
        },
      },
      legend: {
        onClick: null,
        labels: {
          generateLabels: (chart) => {
            const legend = []
            const labels = []
            chart.data.datasets.forEach((item) => {
              const element = {
                text: item.label,
                fillStyle: item.backgroundColor,
                fontColor: "#666",
                hidden: false,
                lineWidth: 0,
                strokeStyle: "rgba(0,0,0,0.1)",
              }
              if (!labels.includes(item.label)) {
                labels.push(item.label)
                legend.push(element)
              }
            })

            return legend
          },
        },
      },
    },
  }

  const cumulCostFullLabels = []
  for (let i = 0; i <= opYears; i++) {
    for (let j = 0; j < selectedUPS.length; j++) {
      cumulCostFullLabels.push(String.fromCharCode(65 + j))
    }
  }

  let cumulCostFullLabelsLegend = ""
  for (let i = 0; i < selectedUPS.length; i++) {
    if (cumulCostFullLabelsLegend !== "") {
      cumulCostFullLabelsLegend += " | "
    }
    cumulCostFullLabelsLegend += String.fromCharCode(65 + i) + " - " + selectedUPS[i].name
  }

  const comulCostFullValuesOpt = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      secondXAxis: {
        axis: "x",
        labels: cumulCostFullLabels,
        grid: {
          drawOnChartArea: false,
        },
        weight: 0,
      },
      x: {
        stacked: true,
        weight: 1,
      },
      y: {
        stacked: true,
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          title: (ttItem) => {
            return `${t("select_ups.spare_product_year")} ${ttItem[0].parsed.x}`
          },
        },
      },
      legend: {
        onClick: null,
        labels: {
          generateLabels: (chart) => {
            const legend = []
            const labels = []
            chart.data.datasets.forEach((item) => {
              const element = {
                text: item.label,
                fillStyle: item.backgroundColor,
                fontColor: "#666",
                hidden: false,
                lineWidth: 0,
                strokeStyle: "rgba(0,0,0,0.1)",
              }
              if (!labels.includes(item.label)) {
                labels.push(item.label)
                legend.push(element)
              }
            })

            return legend
          },
        },
      },
    },
  }

  useEffect(() => {
    const builder = new GraphsBuilder(
      t,
      Constants,
      isAuth,
      advancedConfig,
      selectedUPS,
      kwhPrice,
      opYears,
      cop,
      inflation
    )
    setTotLossesData(builder.buildTotalLosses())
    setTotCostsData(builder.buildTotalCosts())
    setCumulCostsData(builder.buildCumulativeCosts())
    setTCOList(builder.tcoList)
    setCumulCostLineData(builder.buildCumulCostLine())
  }, [selectedUPS, kwhPrice, opYears, cop, inflation, i18n.language, isAuth, advancedConfig, t, setTCOList])

  return (
    <Container style={{ padding: 55 }}>
      <Row className="text-center">
        <Col>
          <img src="icons/sections/sep.png" width="40" height="5" />
        </Col>
      </Row>
      <p className="text-center graphs-section-title">{t("graphs.title")}</p>

      <Row>
        <Col md={6}>
          <Form>
            <Form.Check
              type="checkbox"
              id="losses-checkbox"
              style={{ marginTop: 12, marginBottom: 12 }}
              label={`${t("graphs.total_losses")} (${currency})`}
              checked={showTotLosses}
              onChange={() => {
                setShowTotLosses(!showTotLosses)
              }}
            />
          </Form>
          {showTotLosses ? (
            <div className="graph-container">
              <Bar id="total_losses" options={withoutLegendOpt} data={totLossesData} />
            </div>
          ) : null}
        </Col>

        <Col md={6}>
          <Form>
            <Form.Check
              type="checkbox"
              id="total-checkbox"
              style={{ marginTop: 12, marginBottom: 12 }}
              label={`${t("graphs.total_costs")} (${currency})`}
              checked={showTotCosts}
              onChange={() => {
                setShowTotCosts(!showTotCosts)
              }}
            />
          </Form>
          {showTotCosts ? (
            <div className="graph-container">
              <Bar id="total_costs" options={withStackedValuesOpt} data={totCostsData} />
            </div>
          ) : null}
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Form>
            <Form.Check
              type="checkbox"
              id="cumul-checkbox"
              style={{ marginTop: 12, marginBottom: 12 }}
              label={`${t("graphs.yearly_cost")} (${currency})`}
              checked={showCumulCosts}
              onChange={() => {
                setShowCumulCosts(!showCumulCosts)
              }}
            />
          </Form>
          {showCumulCosts ? (
            <div className="graph-container">
              <Bar id="cumulative_costs" options={withStackedValuesOpt} data={cumulCostsData} />{" "}
            </div>
          ) : null}
        </Col>

        <Col md={6}>
          <Form>
            <Form.Check
              type="checkbox"
              id="cumul-line-checkbox"
              style={{ marginTop: 12, marginBottom: 12 }}
              label={`${t("graphs.cumulative_costs")} (${currency})`}
              checked={showCumulCostLine}
              onChange={() => {
                setShowCumulCostLine(!showCumulCostLine)
              }}
            />
          </Form>
          {showCumulCostLine ? (
            <div className="graph-container">
              <Line id="line_cumulative_costs" data={cumulCostLineData} />
            </div>
          ) : null}
        </Col>
      </Row>
      <div
        id="cumulative_costs_full_container"
        style={{ position: "absolute", left: "-9999px", top: "-9999px", width: "1500px", height: "750px" }}
      >
        <div style={{ textAlign: "center", fontSize: "12px", color: "#666666" }}>{cumulCostFullLabelsLegend}</div>
        <div style={{ width: "100%", height: "730px" }}>
          <Bar id="cumulative_costs_full" options={comulCostFullValuesOpt} data={cumulCostsData} />
        </div>
      </div>
    </Container>
  )
}

GraphsPanel.propTypes = {
  currency: PropTypes.string,
  selectedUPS: PropTypes.array,
  kwhPrice: PropTypes.string,
  opYears: PropTypes.string,
  cop: PropTypes.string,
  inflation: PropTypes.string,
  advancedConfig: PropTypes.bool,
  setTCOList: PropTypes.func,
  showTotCosts: PropTypes.bool,
  setShowTotCosts: PropTypes.func,
  showCumulCosts: PropTypes.bool,
  setShowCumulCosts: PropTypes.func,
  showTotLosses: PropTypes.bool,
  setShowTotLosses: PropTypes.func,
  showCumulCostLine: PropTypes.bool,
  setShowCumulCostLine: PropTypes.func,
}
