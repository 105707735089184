import html2canvas from "html2canvas"
import JsPDF from "jspdf"
import autoTable from "jspdf-autotable"

class PDFBuilder {
  // spacing consts
  SEP_MARGIN = 15
  SECTION_MARGIN = 30
  UPS_MARGIN = 110
  UPS_BOX_HEIGHT = 130
  UPS_ATTRIBUTES_MARGIN = 100
  TCO_GRAPH_MARGIN = 5
  TCO_GRAPH_RIGHT_OFFSET_MARGIN = 50
  TCO_BOX_HEIGHT = 280
  BAR_BOX_HEIGHT = 255

  constructor(t, constants, selectedUPS, tcoList, selectedCountryCO2) {
    this.t = t
    this.constants = constants
    this.selectedUPS = selectedUPS
    this.tcoList = tcoList
    this.selectedCountryCO2 = selectedCountryCO2
    // create pdf with A4 format
    this.pdf = new JsPDF("p", "pt")
    // get common elements
    this.logo = window.document.getElementById("logo")
    this.sep = window.document.getElementById("separator")
    // currency
    const currencySelect = document.querySelector(".form-group-general-info select")
    this.currency = currencySelect.value
    this.currencykWh = window.document.getElementById("currency").innerText
    this.currencySym = constants.currencySymbols[this.currency]
    // doc const
    this.pageWidth = this.pdf.internal.pageSize.getWidth()
    this.upsBoxWidth = this.pageWidth - 50
    // pointer to handle new pages creation
    this.marginPointer = 0
    // images format map
    this.imgFmtSlimTag = "PowerValue 11RT"
    this.imgFmtBigTag = "MegaFlex"
    this.imgFmtShortTag = "PowerLine DPA"
    // init canvas
    this.canvas = null
    // init common items
    this.sepImg = null
    this.smallSepMarginX = null
    this.smallSepWidth = null
    this.smallSepHeight = null
  }

  #transpose(matrix) {
    return matrix[0].map((col, c) => matrix.map((row, r) => matrix[r][c]))
  }

  async downloadReport() {
    // prepare
    await this.buildTitleSection()
    this.buildGeneralInfoSection()
    this.buildUPSSection()
    await this.buildGraphsSection()
    this.buildTableSection()
    await this.buildCo2TableSection()
    // start download
    this.pdf.save("report.pdf")
  }

  async buildTitleSection() {
    // add logo
    this.marginPointer += this.SEP_MARGIN
    this.canvas = await html2canvas(this.logo)
    const img = this.canvas.toDataURL("image/png")
    this.pdf.addImage(img, "png", 15, this.marginPointer, this.logo.clientWidth * 0.5, this.logo.clientHeight * 0.5)

    // add sep
    this.marginPointer += this.SEP_MARGIN
    this.canvas = await html2canvas(this.sep)
    this.sepImg = this.canvas.toDataURL("image/png")
    const sepWidth = this.canvas.width
    const sepHeight = this.canvas.height
    const sepMarginX = (this.pageWidth - sepWidth) / 2

    this.pdf.addImage(this.sepImg, "png", sepMarginX, this.marginPointer, sepWidth, sepHeight)

    // add text
    this.marginPointer += this.SECTION_MARGIN
    const text = this.t("pdf.title")
    this.pdf.setFontSize(18)
    const xOffset =
      this.pdf.internal.pageSize.width / 2 - (this.pdf.getStringUnitWidth(text) * this.pdf.internal.getFontSize()) / 2
    this.pdf.text(text, xOffset, this.marginPointer).setFont(undefined, "bold")
  }

  buildGeneralInfoSection() {
    // add small sep
    this.marginPointer += this.SECTION_MARGIN
    this.smallSepWidth = this.canvas.width * 0.5
    this.smallSepHeight = this.canvas.height * 0.5
    this.smallSepMarginX = (this.pageWidth - this.smallSepWidth) / 2
    // Rects offset and pointer
    const rectStartMargin = 25
    const rectOffset = 110
    let rectPointer = rectStartMargin
    // Labels offset and pointer
    const labelStartMargin = 150
    const labelOffset = 220
    let labelPointer = labelStartMargin

    this.pdf.addImage(
      this.sepImg,
      "png",
      this.smallSepMarginX,
      this.marginPointer,
      this.smallSepWidth,
      this.smallSepHeight
    )

    // add general info title
    this.marginPointer += this.SECTION_MARGIN
    const giTitle = this.t("general_info.title")
    this.pdf.setFontSize(15)
    const giXoffset =
      this.pdf.internal.pageSize.width / 2 -
      (this.pdf.getStringUnitWidth(giTitle) * this.pdf.internal.getFontSize()) / 2
    this.pdf.text(giTitle, giXoffset, this.marginPointer).setFont(undefined, "bold")

    // add electricity price
    this.marginPointer += this.SECTION_MARGIN
    this.pdf.roundedRect(rectStartMargin, this.marginPointer, 80, 76, 1, 1)
    const kwhPriceOffset = labelPointer / 2 - 10
    const kwhPriceLabel = this.t("general_info.kwhPrice")
    this.pdf.setFontSize(10)

    this.pdf.setFont(undefined, "normal")
    this.pdf.text(kwhPriceLabel, kwhPriceOffset, 165, { maxWidth: 80, align: "center" })

    const kwhPrice = window.document.getElementById("kwh-price-input").value + " " + this.currencykWh
    this.pdf.setFontSize(10)

    this.pdf.setFont(undefined, "bold")
    this.pdf.text(kwhPrice, kwhPriceOffset, 210, { maxWidth: 90, align: "center" })

    // add op costs
    rectPointer += rectOffset - 20
    labelPointer += labelOffset
    this.pdf.roundedRect(rectPointer, this.marginPointer, 80, 76, 1, 1)
    const opCostsOffset = labelPointer / 2 - 30
    const opCostsLabel = this.t("general_info.opYears")
    this.pdf.setFontSize(10)

    this.pdf.setFont(undefined, "normal")
    this.pdf.text(opCostsLabel, opCostsOffset, 165, { maxWidth: 80, align: "center" })
    const opCosts = window.document.getElementById("op-years-input").value
    this.pdf.setFontSize(10)

    this.pdf.setFont(undefined, "bold")
    this.pdf.text(opCosts, opCostsOffset, 210, { maxWidth: 80, align: "center" })

    // add cooling
    rectPointer += rectOffset - 20
    labelPointer += labelOffset
    this.pdf.roundedRect(rectPointer, this.marginPointer, 80, 76, 1, 1)
    const coolingOffset = labelPointer / 2 - 50
    const coolingLabel = this.t("general_info.cop")
    this.pdf.setFontSize(10)

    this.pdf.setFont(undefined, "normal")
    this.pdf.text(coolingLabel, coolingOffset, 165, { maxWidth: 80, align: "center" })
    const cooling = window.document.getElementById("cop-input").value
    this.pdf.setFontSize(10)

    this.pdf.setFont(undefined, "bold")
    this.pdf.text(cooling, coolingOffset, 210, { maxWidth: 80, align: "center" })

    // add inflation
    rectPointer += rectOffset - 20
    labelPointer += labelOffset
    this.pdf.roundedRect(rectPointer, this.marginPointer, 80, 76, 1, 1)
    const inflationOffset = labelPointer / 2 - 70
    const inflationLabel = this.t("general_info.inflation")
    this.pdf.setFontSize(10)

    this.pdf.setFont(undefined, "normal")
    this.pdf.text(inflationLabel, inflationOffset, 165, { maxWidth: 80, align: "center" })
    const inflation = window.document.getElementById("inflation-input").value + "%"
    this.pdf.setFontSize(10)

    this.pdf.setFont(undefined, "bold")
    this.pdf.text(inflation, inflationOffset, 210, { maxWidth: 80, align: "center" })

    // add currency
    rectPointer += rectOffset - 20
    labelPointer += labelOffset
    this.pdf.roundedRect(rectPointer, this.marginPointer, 80, 76, 1, 1)
    const currencyOffset = labelPointer / 2 - 90
    const currencyLabel = this.t("general_info.currency")
    this.pdf.setFontSize(10)

    this.pdf.setFont(undefined, "normal")
    this.pdf.text(currencyLabel, currencyOffset, 165, { maxWidth: 80, align: "center" })
    this.pdf.setFontSize(10)

    this.pdf.setFont(undefined, "bold")
    this.pdf.text(this.currency, currencyOffset, 210, { maxWidth: 80, align: "center" })

    // add currency
    rectPointer += rectOffset - 20
    labelPointer += labelOffset
    this.pdf.roundedRect(rectPointer, this.marginPointer, 80, 76, 1, 1)
    const co2CountryOffset = labelPointer / 2 - 110
    const co2CountryLabel = this.t("general_info.country")
    this.pdf.setFontSize(10)

    this.pdf.setFont(undefined, "normal")
    this.pdf.text(co2CountryLabel, co2CountryOffset, 165, { maxWidth: 80, align: "center" })
    this.pdf.setFontSize(10)

    const selectedCountry = document.getElementById("dropdown-input").value
    const selectedCountryYoffset = selectedCountry.length < 20 ? 210 : 190
    this.pdf.setFont(undefined, "bold")
    this.pdf.text(selectedCountry, co2CountryOffset, selectedCountryYoffset, { maxWidth: 80, align: "center" })
  }

  buildUPSSection() {
    // add UPS
    this.marginPointer += this.UPS_MARGIN
    this.pdf.addImage(
      this.sepImg,
      "png",
      this.smallSepMarginX,
      this.marginPointer,
      this.smallSepWidth,
      this.smallSepHeight
    )

    // add ups title
    this.marginPointer += this.SECTION_MARGIN
    const upsTitle = this.t("general.ups")
    this.pdf.setFontSize(15)
    const upsOffset =
      this.pdf.internal.pageSize.width / 2 -
      (this.pdf.getStringUnitWidth(upsTitle) * this.pdf.internal.getFontSize()) / 2
    this.pdf.text(upsTitle, upsOffset, this.marginPointer).setFont(undefined, "bold")

    // margin for ups image
    const marginFromBorder = 50
    const availableImgSpace = 90

    for (const upsIndex in this.selectedUPS) {
      // create a new page, if needed
      if (this.marginPointer + this.SECTION_MARGIN + this.UPS_BOX_HEIGHT > this.pdf.internal.pageSize.height) {
        this.pdf.addPage()
        this.marginPointer = 25
      }

      const ups = this.selectedUPS[upsIndex]
      this.marginPointer += this.SECTION_MARGIN
      this.pdf.roundedRect(25, this.marginPointer, this.upsBoxWidth, this.UPS_BOX_HEIGHT, 1, 1)

      // build ups card
      const upsName = ups.name

      this.pdf.text(upsName, marginFromBorder, this.marginPointer + 25).setFont(undefined, "bold")

      // add image
      let imgFmt = [40, 82]
      let imgMarginY = 35

      if (upsName.includes(this.imgFmtSlimTag)) {
        imgFmt = [70, 23]
        imgMarginY = 70
      }

      if (upsName.includes(this.imgFmtBigTag)) imgFmt = [93, 61]

      if (upsName.includes(this.imgFmtShortTag)) imgFmt = [60, 82]

      if (ups.is_custom) imgFmt = [50, 82]

      const imgMarginX = availableImgSpace - imgFmt[0] > 0 ? (availableImgSpace - imgFmt[0]) / 2 : 0

      let upsImg = `https:${ups.image}`

      if (ups.is_custom) {
        upsImg = window.document.getElementById(`ups-img-${upsIndex}`)
      }

      const upsImgWidth = imgFmt[0]
      const upsImgHeight = imgFmt[1]
      const xCoordinate = marginFromBorder + imgMarginX
      const yCoordinate = this.marginPointer + imgMarginY

      this.pdf.addImage(upsImg, "JPEG", xCoordinate, yCoordinate, upsImgWidth, upsImgHeight)

      // add rating
      const ratingLabel = this.t("select_ups.power_rating")
      this.pdf.setFontSize(11)
      this.pdf.setFont(undefined, "normal")
      this.pdf.text(ratingLabel, 190, this.marginPointer + 60, { maxWidth: 90, align: "center" })
      const rating = ups.rated_power === undefined ? ups.ratedActivePowers[0] : ups.rated_power
      const ratingValue = rating.toString() + "kW"
      this.pdf.setFontSize(16)
      this.pdf.setFont(undefined, "bold")
      this.pdf.text(ratingValue, 190, this.marginPointer + 90, { maxWidth: 90, align: "center" })

      // add price
      const priceLabel = this.t("select_ups.ups_price")
      this.pdf.setFontSize(11)
      this.pdf.setFont(undefined, "normal")
      this.pdf.text(priceLabel, 190 + this.UPS_ATTRIBUTES_MARGIN, this.marginPointer + 60, {
        maxWidth: 90,
        align: "center",
      })
      const priceValue = ups.unit_price === undefined ? "-" : ups.unit_price.toString() + this.currencySym
      this.pdf.setFontSize(16)
      this.pdf.setFont(undefined, "bold")
      this.pdf.text(priceValue, 190 + this.UPS_ATTRIBUTES_MARGIN, this.marginPointer + 90, {
        maxWidth: 90,
        align: "center",
      })

      // add load
      const loadLabel = this.t("select_ups.tot_load")
      this.pdf.setFontSize(11)
      this.pdf.setFont(undefined, "normal")
      this.pdf.text(loadLabel, 290 + this.UPS_ATTRIBUTES_MARGIN, this.marginPointer + 60, {
        maxWidth: 90,
        align: "center",
      })
      const loadValue = ups.total_load === undefined ? "-" : ups.total_load.toString()
      this.pdf.setFontSize(16)
      this.pdf.setFont(undefined, "bold")
      this.pdf.text(loadValue, 290 + this.UPS_ATTRIBUTES_MARGIN, this.marginPointer + 90, {
        maxWidth: 90,
        align: "center",
      })

      // add efficiency
      const efficiencyLabel = this.t("select_ups.efficiency")
      this.pdf.setFontSize(11)
      this.pdf.setFont(undefined, "normal")
      this.pdf.text(efficiencyLabel, 390 + this.UPS_ATTRIBUTES_MARGIN, this.marginPointer + 60, {
        maxWidth: 90,
        align: "center",
      })
      const efficiency = window.document.getElementById(`efficiency-${upsIndex}`).value
      this.pdf.setFontSize(16)
      this.pdf.setFont(undefined, "bold")
      this.pdf.text(efficiency, 390 + this.UPS_ATTRIBUTES_MARGIN, this.marginPointer + 90, {
        maxWidth: 90,
        align: "center",
      })

      // update pointer
      this.marginPointer += this.UPS_BOX_HEIGHT
    }
  }

  async buildGraphsSection() {
    this.marginPointer += this.SECTION_MARGIN

    // create a new page
    this.pdf.addPage()
    this.marginPointer = 25

    // add small sep
    this.pdf.addImage(
      this.sepImg,
      "png",
      this.smallSepMarginX,
      this.marginPointer,
      this.smallSepWidth,
      this.smallSepHeight
    )

    // add graphs title
    this.marginPointer += this.SECTION_MARGIN
    const graphsTitle = this.t("graphs.title")
    this.pdf.setFontSize(15)
    const graphsTitleOffset =
      this.pdf.internal.pageSize.width / 2 -
      (this.pdf.getStringUnitWidth(graphsTitle) * this.pdf.internal.getFontSize()) / 2
    this.pdf.text(graphsTitle, graphsTitleOffset, this.marginPointer).setFont(undefined, "bold")

    this.marginPointer += this.SEP_MARGIN

    // add UPS graphs
    for (let upsIndex = 0; upsIndex < this.selectedUPS.length; upsIndex++) {
      const graph = window.document.getElementById(`tco_${upsIndex + 1}`)
      // add doughnut
      this.marginPointer += this.TCO_GRAPH_MARGIN
      this.canvas = await html2canvas(graph)
      const img = this.canvas.toDataURL("image/png")

      const imgWidth = this.canvas.width / this.pdf.internal.scaleFactor
      const graphsOffset = (this.pdf.internal.pageSize.width - imgWidth) / 2 + this.TCO_GRAPH_RIGHT_OFFSET_MARGIN
      this.pdf.addImage(img, "png", graphsOffset, this.marginPointer)
      this.pdf.roundedRect(25, this.marginPointer - 10, this.upsBoxWidth, this.TCO_BOX_HEIGHT, 1, 1)
      this.marginPointer += this.TCO_BOX_HEIGHT + this.SEP_MARGIN

      // create a new page, if needed
      if (this.marginPointer + this.TCO_BOX_HEIGHT > this.pdf.internal.pageSize.height) {
        this.pdf.addPage()
        this.marginPointer = 25
      }
    }

    const lossesCheck = window.document.getElementById("losses-checkbox")

    if (lossesCheck.checked) {
      if (this.selectedUPS.length % 2 !== 0) {
        // create a new page
        this.pdf.addPage()
        this.marginPointer = 25
      }

      // add total losses title
      this.marginPointer += this.SEP_MARGIN
      const lossesTitle = `${this.t("graphs.total_losses")} (${this.currency})`
      this.pdf.setFontSize(15)
      const lossesTitleOffset =
        this.pdf.internal.pageSize.width / 2 -
        (this.pdf.getStringUnitWidth(lossesTitle) * this.pdf.internal.getFontSize()) / 2
      this.pdf.text(lossesTitle, lossesTitleOffset, this.marginPointer).setFont(undefined, "bold")

      this.marginPointer += this.SEP_MARGIN
      const lossesGraph = window.document.getElementById("total_losses")
      this.marginPointer += this.TCO_GRAPH_MARGIN
      this.canvas = await html2canvas(lossesGraph, { scale: 2 })
      const graphWidth = this.upsBoxWidth - this.TCO_GRAPH_RIGHT_OFFSET_MARGIN
      const graphHeight = this.BAR_BOX_HEIGHT - this.TCO_GRAPH_MARGIN * 2
      const graphsOffset = (this.pdf.internal.pageSize.width - graphWidth) / 2
      this.pdf.addImage(
        this.canvas.toDataURL("image/png"),
        "png",
        graphsOffset,
        this.marginPointer,
        graphWidth,
        graphHeight
      )
      this.pdf.roundedRect(25, this.marginPointer - 10, this.upsBoxWidth, this.BAR_BOX_HEIGHT, 1, 1)

      this.marginPointer += graphHeight + this.BAR_BOX_HEIGHT + this.SEP_MARGIN + this.SECTION_MARGIN
    }

    const totalCheck = window.document.getElementById("total-checkbox")

    if (totalCheck.checked) {
      this.marginPointer = 400

      // add total cost title
      const costTitle = `${this.t("graphs.total_costs")} (${this.currency})`
      this.pdf.setFontSize(15)
      const costTitleOffset =
        this.pdf.internal.pageSize.width / 2 -
        (this.pdf.getStringUnitWidth(costTitle) * this.pdf.internal.getFontSize()) / 2
      this.pdf.text(costTitle, costTitleOffset, this.marginPointer).setFont(undefined, "bold")
      this.marginPointer += this.SEP_MARGIN
      const costGraph = window.document.getElementById("total_costs")
      this.marginPointer += this.TCO_GRAPH_MARGIN
      this.canvas = await html2canvas(costGraph, { scale: 2 })
      const graphWidth = this.upsBoxWidth - this.TCO_GRAPH_RIGHT_OFFSET_MARGIN
      const graphHeight = this.BAR_BOX_HEIGHT - this.TCO_GRAPH_MARGIN * 2
      const costOffset = (this.pdf.internal.pageSize.width - graphWidth) / 2
      this.pdf.addImage(
        this.canvas.toDataURL("image/png"),
        "png",
        costOffset,
        this.marginPointer,
        graphWidth,
        graphHeight
      )
      this.pdf.roundedRect(25, this.marginPointer - 10, this.upsBoxWidth, this.BAR_BOX_HEIGHT, 1, 1)
      this.marginPointer += this.BAR_BOX_HEIGHT + this.SEP_MARGIN + this.SECTION_MARGIN
    }

    const cumulCheck = window.document.getElementById("cumul-checkbox")

    // BAR CUMUL CHECK
    if (cumulCheck.checked) {
      if (this.marginPointer + this.SEP_MARGIN + this.BAR_BOX_HEIGHT > this.pdf.internal.pageSize.height) {
        this.pdf.addPage()
        this.marginPointer = 25
      }

      this.marginPointer += this.SEP_MARGIN
      // add cumulative cost title
      const cumulTitle = `${this.t("graphs.yearly_cost")} (${this.currency})`
      this.pdf.setFontSize(15)
      const cumulTitleOffset =
        this.pdf.internal.pageSize.width / 2 -
        (this.pdf.getStringUnitWidth(cumulTitle) * this.pdf.internal.getFontSize()) / 2
      this.pdf.text(cumulTitle, cumulTitleOffset, this.marginPointer).setFont(undefined, "bold")

      this.marginPointer += this.SEP_MARGIN

      const cumulGraph = window.document.getElementById("cumulative_costs_full_container")
      this.marginPointer += this.TCO_GRAPH_MARGIN

      this.canvas = await html2canvas(cumulGraph)
      const graphWidth = this.upsBoxWidth - this.TCO_GRAPH_RIGHT_OFFSET_MARGIN
      const graphHeight = this.BAR_BOX_HEIGHT - this.TCO_GRAPH_MARGIN * 2
      const cumulOffset = (this.pdf.internal.pageSize.width - graphWidth) / 2

      this.pdf.addImage(
        this.canvas.toDataURL("image/png"),
        "png",
        cumulOffset,
        this.marginPointer,
        graphWidth,
        graphHeight
      )

      this.pdf.roundedRect(25, this.marginPointer - 10, this.upsBoxWidth, this.BAR_BOX_HEIGHT, 1, 1)
      this.marginPointer += graphHeight + this.BAR_BOX_HEIGHT + this.SEP_MARGIN
    }

    const cumulLineCheck = window.document.getElementById("cumul-line-checkbox")

    // LINE CUMUL CHECK
    if (cumulLineCheck.checked) {
      this.marginPointer = 400
      // add cumulative cost title
      const cumulTitle = `${this.t("graphs.cumulative_costs")} (${this.currency})`
      this.pdf.setFontSize(15)
      const cumulTitleOffset =
        this.pdf.internal.pageSize.width / 2 -
        (this.pdf.getStringUnitWidth(cumulTitle) * this.pdf.internal.getFontSize()) / 2
      this.pdf.text(cumulTitle, cumulTitleOffset, this.marginPointer).setFont(undefined, "bold")
      this.marginPointer += this.SEP_MARGIN
      const cumulGraph = window.document.getElementById("line_cumulative_costs")
      this.marginPointer += this.TCO_GRAPH_MARGIN
      this.canvas = await html2canvas(cumulGraph, { scale: 2 })
      const graphWidth = this.upsBoxWidth - this.TCO_GRAPH_RIGHT_OFFSET_MARGIN
      const graphHeight = this.BAR_BOX_HEIGHT - this.TCO_GRAPH_MARGIN * 2
      const cumulOffset = (this.pdf.internal.pageSize.width - graphWidth) / 2
      this.pdf.addImage(
        this.canvas.toDataURL("image/png"),
        "png",
        cumulOffset,
        this.marginPointer,
        graphWidth,
        graphHeight
      )
      this.pdf.roundedRect(25, this.marginPointer - 10, this.upsBoxWidth, this.BAR_BOX_HEIGHT, 1, 1)

      this.marginPointer += graphHeight + this.BAR_BOX_HEIGHT + this.SEP_MARGIN
    }
  }

  buildTableSection() {
    this.pdf.addPage()
    this.marginPointer = 25

    // add small sep
    this.pdf.addImage(
      this.sepImg,
      "png",
      this.smallSepMarginX,
      this.marginPointer,
      this.smallSepWidth,
      this.smallSepHeight
    )

    // add table title
    this.marginPointer += this.SECTION_MARGIN
    const tableTitle = this.t("pdf.tables")
    this.pdf.setFontSize(15)
    const tableTitleOffset =
      this.pdf.internal.pageSize.width / 2 -
      (this.pdf.getStringUnitWidth(tableTitle) * this.pdf.internal.getFontSize()) / 2
    this.pdf.text(tableTitle, tableTitleOffset, this.marginPointer).setFont(undefined, "bold")

    this.marginPointer += this.SEP_MARGIN

    // add add_costs title
    this.marginPointer += this.SECTION_MARGIN
    const addCostsTitle = this.t("pdf.add_costs")
    this.pdf.setFontSize(12)
    this.pdf.text(addCostsTitle, 25, this.marginPointer).setFont(undefined, "bold")

    this.marginPointer += this.SEP_MARGIN

    const addCostsHead = []
    const serviceCosts = []
    const serviceYear = []
    const batteryCost = []
    const batteryLife = []
    const spareCosts = []
    const cumulCostsColumn = []

    // prepare data
    for (let upsIndex = 0; upsIndex < this.selectedUPS.length; upsIndex++) {
      const ups = this.selectedUPS[upsIndex]
      addCostsHead.push(
        ups.is_custom ? `${this.t("general.custom_ups_name")}` : `${this.t("general.ups")} ${upsIndex + 1}`
      )
      serviceCosts.push(ups.contract_costs_enabled ? ups.contract_costs : 0)
      serviceYear.push(ups.contract_costs_enabled ? ups.contract_starting_year : "-")
      batteryCost.push(ups.replace_battery_cost_enabled ? ups.replace_battery_cost : 0)
      batteryLife.push(ups.replace_battery_cost_enabled ? ups.battery_life : "-")
      spareCosts.push(this.tcoList[upsIndex].spareParts.total)
      cumulCostsColumn.push(this.tcoList[upsIndex].cumulative)
    }

    const cumulCostsRow = this.#transpose(cumulCostsColumn)
    const cumulCosts = cumulCostsRow.map((row, year) => {
      row.unshift(year)
      return row
    })

    autoTable(this.pdf, {
      theme: "plain",
      margin: { top: this.marginPointer },
      headStyles: { lineWidth: { bottom: 1.5, top: 0, left: 0, right: 0 }, fillColor: "#ffffff" },
      bodyStyles: { lineWidth: { bottom: 0.5, top: 0, left: 0, right: 0 }, fillColor: "#ffffff" },
      tableLineWidth: 0.5,
      head: [[this.t("pdf.add_costs").toUpperCase(), ...addCostsHead]],
      body: [
        [this.t("select_ups.service_contr_costs"), ...serviceCosts],
        [this.t("select_ups.service_contract_starting_year"), ...serviceYear],
        [this.t("select_ups.replace_battery_cost"), ...batteryCost],
        [this.t("select_ups.battery_life_time"), ...batteryLife],
        [this.t("pdf.spare_parts_cost"), ...spareCosts],
      ],
    })

    this.marginPointer = this.pdf.lastAutoTable.finalY

    // spare parts tables
    for (let upsIndex = 0; upsIndex < this.selectedUPS.length; upsIndex++) {
      const ups = this.selectedUPS[upsIndex]

      if (ups.spare_parts.length > 0) {
        // add spare parts title
        this.marginPointer += this.SECTION_MARGIN
        const spareTitle = ups.is_custom
          ? `${this.t("general.custom_ups_name")} - ${this.t("select_ups.spare_parts_list")}`
          : `${this.t("general.ups")} ${upsIndex + 1} - ${this.t("select_ups.spare_parts_list")}`
        this.pdf.setFontSize(12)
        this.pdf.text(spareTitle, 25, this.marginPointer).setFont(undefined, "bold")

        this.marginPointer += this.SEP_MARGIN

        const sparePartList = []

        for (const part of ups.spare_parts) {
          sparePartList.push([part.name, part.year, part.cost])
        }

        autoTable(this.pdf, {
          theme: "plain",
          startY: this.marginPointer,
          headStyles: { lineWidth: { bottom: 1.5, top: 0, left: 0, right: 0 }, fillColor: "#ffffff" },
          bodyStyles: { lineWidth: { bottom: 0.5, top: 0, left: 0, right: 0 }, fillColor: "#ffffff" },
          tableLineWidth: 0.5,
          head: [
            [
              this.t("select_ups.spare_product_name"),
              this.t("select_ups.spare_product_year"),
              this.t("select_ups.spare_product_cost"),
            ],
          ],
          body: [...sparePartList],
        })

        this.marginPointer = this.pdf.lastAutoTable.finalY
      }
    }

    // add cumul costs title
    this.marginPointer += this.SECTION_MARGIN
    const cumulTitle = this.t("graphs.yearly_cost")
    this.pdf.setFontSize(12)
    this.pdf.text(cumulTitle, 25, this.marginPointer).setFont(undefined, "bold")

    this.marginPointer += this.SEP_MARGIN

    autoTable(this.pdf, {
      theme: "plain",
      startY: this.marginPointer,
      headStyles: { lineWidth: { bottom: 1.5, top: 0, left: 0, right: 0 }, fillColor: "#ffffff" },
      bodyStyles: { lineWidth: { bottom: 0.5, top: 0, left: 0, right: 0 }, fillColor: "#ffffff" },
      tableLineWidth: 0.5,
      head: [[this.t("select_ups.spare_product_year"), ...addCostsHead]],
      body: cumulCosts,
    })

    this.marginPointer = this.pdf.lastAutoTable.finalY
  }

  async buildCo2TableSection() {
    const labelsLosses = this.selectedUPS.map((ups) => ups.name)

    const totalLosses = {
      labelsLosses,
      datasets: [
        {
          data: labelsLosses.map((label, labelIndex) => this.tcoList[labelIndex].losses.total),
          backgroundColor: this.constants.graphsComponents.losses,
        },
      ],
    }

    const data = totalLosses.datasets[0].data
    const labels = totalLosses.labelsLosses
    const sortedData = labels
      .map((label, index) => ({
        label,
        value: data[index],
      }))
      .sort((a, b) => a.value - b.value)

    const calculateSavedCO2 = (totalKWhProduct2) => {
      if (this.selectedCountryCO2 && parseInt(this.selectedCountryCO2) !== 0) {
        const result = ((sortedData[0].value - totalKWhProduct2) * this.selectedCountryCO2) / 1000
        const positiveResult = Math.abs(result)
        return parseInt(positiveResult.toString().replace(/,/g, ""), 10)
      }
      return 0
    }

    if (this.marginPointer + this.SEP_MARGIN > this.pdf.internal.pageSize.height) {
      this.pdf.addPage()
      this.marginPointer = 25
    }

    this.marginPointer += this.SEP_MARGIN
    this.marginPointer += this.SEP_MARGIN
    const co2Title = `${this.t("select_ups.co2_saved")}`
    this.pdf.setFontSize(12)
    this.pdf.text(co2Title, 25, this.marginPointer).setFont(undefined, "bold")
    this.marginPointer += this.SEP_MARGIN
    this.pdf.setFont(undefined, "normal")

    const firstUPSLabel = sortedData[0].label
    sortedData.slice(1).forEach((dataPoint) => {
      const savedCO2 = calculateSavedCO2(dataPoint.value)
      const text = `${firstUPSLabel} ${this.t("general.saves")} ${savedCO2} ${this.t("general.saves_unit")} ${
        dataPoint.label
      }`
      this.pdf.text(text, 25, this.marginPointer)
      this.marginPointer += this.SEP_MARGIN
    })

    this.marginPointer += this.SEP_MARGIN
  }
}

export default PDFBuilder
