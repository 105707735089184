export const Constants = {
  
  // all available languages
  languages: ["EN", "IT", "ES", "DE", "FR"],

  languageCode: {
    "EN": "en",
    "IT": "it",
    "ES": "es",
    "DE": "de",
    "FR": "fr",
  },

  // all available currencies
  currencies: ["€", "$"],

  currencyNames: {
    "€": "EUR",
    "$": "USD"
  },

  currencySymbols: {
    "EUR": "€",
    "USD": "$"
  },

  // General info default values
  GeneralInfoDefaultValues: {
    kwhPrice: "0.4",
    opYears: "10",
    cop: "2",
    inflation: "5",
  },

  // UPS default values
  UPSDefaultValues: {
    quantity: 1,
    total_load: 0,
    unit_price: 0,
    contract_costs: 0,
    contract_starting_year: 1,
    replace_battery_cost: 0,
    battery_life: 3,
    power_exchange: 0,
    consumables: 0
  },

  // Spare part default values
  SparePartDefaultValues: {
    name: "Part",
    year: 0,
    cost: 0,
  },

   // Graphs colors - UPS
   graphsUPSColors: {
    0: "rgb(153, 0, 0)",
    1: "rgb(0, 0, 255)",
    2: "rgb(0, 255, 0)",
    3: "rgb(255, 255, 0)",
    4: "rgb(255, 0, 255)",
    5: "rgb(0, 0, 0)",
    6: "rgb(99, 255, 105)"
  },

  // Graphs colors - Components
  graphsComponents: {
    losses: "rgb(255, 0, 0)",
    total_costs: "rgb(0, 255, 196)"
  },

  graphsTCOColors: [
    "rgb(255, 99, 132)",
    "rgb(53, 162, 235)",
    "rgb(255, 206, 86)",
    "rgb(75, 192, 192)",
    "rgb(153, 102, 255)",
    "rgb(255, 159, 64)",
    "rgb(0, 0, 0)",
    "rgb(200, 200, 200)",
    "rgb(0, 159, 64)",
    "rgb(0, 99, 132)",
    "rgb(154, 0, 0)",
  ],
}
