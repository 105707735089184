import PropTypes from "prop-types"
import { useMsal, AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react"
import { useTranslation } from "react-i18next"

import Navbar from "react-bootstrap/Navbar"
import Nav from "react-bootstrap/Nav"
import NavDropdown from "react-bootstrap/NavDropdown"
import Button from "react-bootstrap/Button"
import { Constants } from "../util/Constants"
import PDFBuilder from "../util/builders/PDFBuilder"

export default function NavbarComponent({ language, setLanguage, selectedUPS, tcoList, saveDataToLocalStorage, selectedCountryCO2 }) {
  // useMsal hook will return the PublicClientApplication instance
  const { instance, accounts } = useMsal()

  const { t, i18n } = useTranslation()

  const login = () => {
    saveDataToLocalStorage()
    instance.loginRedirect()
  }

  const logout = () => {
    instance.logoutRedirect()
  }

  const buildUserDropdown = (t) => {
    if (accounts.length > 0) {
      const username = accounts[0].username
      return (
        <NavDropdown id="user-dropdown" className="login-dropdown" align="end" title={username}>
          <NavDropdown.Item onClick={logout}>{t("navbar.logout")}</NavDropdown.Item>
        </NavDropdown>
      )
    }
  }

  const toPDF = async () => {
    const builder = new PDFBuilder(t, Constants, selectedUPS, tcoList,selectedCountryCO2)
    await builder.downloadReport()
  }

  return (
    <Navbar className="navbar-home" fixed="top">
      <Navbar.Brand href="/">
        <img id="logo" src="icons/navbar/logo.svg" width="78" height="30" className="d-inline-block align-top" />
      </Navbar.Brand>
      <p className="app-title">{t("general.title")}</p>
      <Navbar.Collapse>
        <Nav className="justify-content-end gap-4" style={{ width: "100%" }}>
          <Button className="download-pdf-button text-nowrap" onClick={toPDF}>
            <img src="icons/navbar/download.png" width="13" height="12" className="download-pdf-button-image" />
            {t("navbar.export_pdf")}
          </Button>
          <UnauthenticatedTemplate>
            <Button className="login-button" onClick={login}>
              {t("navbar.login")}
            </Button>
          </UnauthenticatedTemplate>
          <AuthenticatedTemplate>{buildUserDropdown(t)}</AuthenticatedTemplate>
          <NavDropdown
            id="collasible-nav-dropdown"
            onSelect={setLanguage}
            title={language}
            className="languages-dropdown"
          >
            {Constants.languages.map((l) => (
              <NavDropdown.Item
                eventKey={l}
                key={l}
                className="languages-dropdown-item"
                onClick={() => {
                  i18n.changeLanguage(Constants.languageCode[l])
                }}
              >
                {l}
              </NavDropdown.Item>
            ))}
          </NavDropdown>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
}

NavbarComponent.propTypes = {
  language: PropTypes.string,
  setLanguage: PropTypes.func,
  selectedUPS: PropTypes.array,
  tcoList: PropTypes.array,
  saveDataToLocalStorage: PropTypes.func,
  selectedCountryCO2: PropTypes.string
}
