import PropTypes from "prop-types"
import { useState } from "react"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Form from "react-bootstrap/Form"
import InputGroup from "react-bootstrap/InputGroup"
import TCOMath from "../util/TCOMath"
import { Constants } from "../util/Constants"
import { useTranslation } from "react-i18next"
import { Tooltip } from "react-tooltip"
import CountryDropdown from "./CountryDropdown"

export default function GeneralInfoPanel({
  currency,
  setCurrency,
  kwhPrice,
  setKwhPrice,
  opYears,
  setOpYears,
  cop,
  setCop,
  inflation,
  setInflation,
  setSelectedCountry,
  csvData,
}) {
  const { t } = useTranslation()
  const [invalidCOP, setInvalidCOP] = useState(false)
  const [invalidOpYears, setInvalidOpYears] = useState(false)

  const validateCOP = (cop) => {
    const normValue = TCOMath.normalize(cop, parseInt)

    if (normValue < 1 || normValue > 5) {
      setInvalidCOP(true)
    } else {
      setInvalidCOP(false)
    }
  }

  const validateOpYears = (opYears) => {
    const normValue = TCOMath.normalize(opYears, parseInt)

    if (normValue < 5 || normValue > 25) {
      setInvalidOpYears(true)
    } else {
      setInvalidOpYears(false)
    }
  }

  return (
    <Container style={{ padding: 55 }}>
      <Row className="text-center">
        <Col>
          <img id="separator" src="icons/sections/sep.png" width="40" height="5" />
        </Col>
      </Row>
      <p className="text-center general-info-title">{t("general_info.title")}</p>
      <div className="form-general-info-container">
        <div className="form-group-general-info">
          <Form.Label>{t("general_info.kwhPrice")}</Form.Label>
          <InputGroup className="mb-3">
            <Form.Control
              id="kwh-price-input"
              type="number"
              value={kwhPrice}
              onChange={(event) => {
                setKwhPrice(event.target.value)
              }}
            />
            <InputGroup.Text id="currency">{currency}/kWh</InputGroup.Text>
          </InputGroup>
        </div>

        <div className="form-group-general-info">
          <Form.Label>{t("general_info.opYears")}</Form.Label>
          <InputGroup className="mb-3">
            <Form.Control
              id="op-years-input"
              type="number"
              value={opYears}
              onChange={(event) => {
                validateOpYears(event.target.value)
                setOpYears(event.target.value)
              }}
              isInvalid={invalidOpYears}
            />
            <Form.Control.Feedback type="invalid">{t("general.invalid_opYears")}</Form.Control.Feedback>
            {invalidOpYears ? null : <InputGroup.Text>{t("general_info.years")}</InputGroup.Text>}
          </InputGroup>
        </div>

        <div className="form-group-general-info">
          <Form.Label data-tooltip-id="cop-tooltip" data-tooltip-content={t("general_info.cop_tooltip")}>
            {t("general_info.cop")}
          </Form.Label>
          <Tooltip id="cop-tooltip" />
          <InputGroup className="mb-3">
            <Form.Control
              id="cop-input"
              type="number"
              value={cop}
              onChange={(event) => {
                validateCOP(event.target.value)
                setCop(event.target.value)
              }}
              isInvalid={invalidCOP}
            />
            <Form.Control.Feedback type="invalid">{t("general.invalid_cop")}</Form.Control.Feedback>
          </InputGroup>
        </div>

        <div className="form-group-general-info">
          <Form.Label>{t("general_info.inflation")}</Form.Label>
          <InputGroup className="mb-3">
            <Form.Control
              id="inflation-input"
              type="number"
              value={inflation}
              onChange={(event) => {
                setInflation(event.target.value)
              }}
            />
            <InputGroup.Text>%</InputGroup.Text>
          </InputGroup>
        </div>

        <div className="form-group-general-info">
          <Form.Label>{t("general_info.currency")}</Form.Label>
          <Form.Select onChange={(event) => setCurrency(event.target.value)} value={currency}>
            {Constants.currencies.map((curr) => (
              <option key={curr}>{Constants.currencyNames[curr]}</option>
            ))}
          </Form.Select>
        </div>

        <div className="form-group-general-info">
          <Form.Label>{t("general_info.country")}</Form.Label>
          <CountryDropdown csvData={csvData} setSelectedCountry={setSelectedCountry}></CountryDropdown>
        </div>
      </div>
    </Container>
  )
}

GeneralInfoPanel.propTypes = {
  currency: PropTypes.string,
  setCurrency: PropTypes.func,
  kwhPrice: PropTypes.string,
  setKwhPrice: PropTypes.func,
  opYears: PropTypes.string,
  setOpYears: PropTypes.func,
  cop: PropTypes.string,
  setCop: PropTypes.func,
  inflation: PropTypes.string,
  setInflation: PropTypes.func,
  setSelectedCountry: PropTypes.func,
  csvData: PropTypes.array,
  selectedCountry: PropTypes.string,
}
