class TCOMath {
  static normalize(value, parser) {
    return value === undefined || value === "" || value === "-" || isNaN(value) ? 0 : parser(value)
  }

  static solveTCOCost(ups, upsIndex, kwhPrice, opYears, cop, inflation, isAuth, advancedConfig) {
    // solve product cost
    const product = this.solveProduct(ups)
    // solve losses cost
    const losses = this.solveLosses(kwhPrice, opYears, cop, inflation, ups, upsIndex)

    let contractsCost = { data: [], total: 0 }
    let batteryCost = { data: [], total: 0 }
    let consumablesCost = 0
    let powerExchange = { data: [], total: 0 }
    let sparePartsCost = { data: [], total: 0, subTotals: [], cumulativeTotals: [] }

    if (advancedConfig) {
      // solve contract cost
      contractsCost = this.solveContractsCost(ups, opYears, inflation)
      // solve battery cost
      batteryCost = this.solveBatteryCost(ups, opYears, inflation)
    }

    if (advancedConfig && isAuth) {
      // solve consumables cost
      consumablesCost = this.solveConsumablesCost(ups)
      // solve power exchange credit
      powerExchange = this.solvePowerExchange(ups, opYears)
      // solve spare parts cost
      sparePartsCost = this.solveSparePartsCost(ups, opYears, inflation)
    }

    // solve cumulative costs
    const cumulCosts = this.solveCumulativeCosts(
      opYears,
      product,
      losses,
      contractsCost,
      batteryCost,
      sparePartsCost,
      isAuth,
      advancedConfig
    )

    // total cost
    const totalCost = this.normalize(
      product + losses.total + contractsCost.total + batteryCost.total + consumablesCost + sparePartsCost.total,
      parseFloat
    )

    const tcoCost = totalCost - powerExchange.total

    // solve cost percentages (over total) per cost item
    const productPercent = this.normalize(product / totalCost, parseFloat)
    const lossesPercent = this.normalize(losses.total / totalCost, parseFloat)
    const contractsPercent = this.normalize(contractsCost.total / totalCost, parseFloat)
    const batteryPercent = this.normalize(batteryCost.total / totalCost, parseFloat)
    const consumablesPercent = this.normalize(consumablesCost / totalCost, parseFloat)
    const sparePartsPercent = []
    const sparePartsLabels = []

    for (let partIndex = 0; partIndex < ups.spare_parts.length; partIndex++) {
      sparePartsPercent[partIndex] = Math.floor(
        this.normalize(sparePartsCost.subTotals[partIndex] / totalCost, parseFloat) * 100
      )
      sparePartsLabels[partIndex] = ups.spare_parts[partIndex].name
    }

    // console.log("-----------------------------------")
    // console.log("product: ", product)
    // console.log("losses: ", losses)
    // console.log("contracts: ", contractsCost)
    // console.log("spareParts: ", sparePartsCost)
    // console.log("battery: ", batteryCost)
    // console.log("consumables: ", consumablesCost)
    // console.log("powerExchange: ", powerExchange)
    // console.log("cumulative: ", cumulCosts)
    // console.log("product %: ", productPercent)
    // console.log("losses %: ", lossesPercent)
    // console.log("contracts %: ", contractsPercent)
    // console.log("spareParts %: ", sparePartsPercent)
    // console.log("sparePartsLabels: ", sparePartsLabels)
    // console.log("battery %: ", batteryPercent)
    // console.log("consumables %: ", consumablesPercent)
    // console.log("-----------------------------------")

    return {
      years: this.normalize(opYears, parseInt),
      product,
      productPercent,
      losses,
      lossesPercent,
      contract: contractsCost,
      contractsPercent,
      battery: batteryCost,
      batteryPercent,
      consumables: consumablesCost,
      consumablesPercent,
      powerExchange,
      spareParts: sparePartsCost,
      sparePartsPercent,
      sparePartsLabels,
      cumulative: cumulCosts,
      totalCost,
      tcoTotal: tcoCost,
    }
  }

  static solveProduct(ups) {
    const product = this.normalize(ups.unit_price, parseFloat)
    const upsQuantity = this.normalize(ups.quantity, parseInt)

    return product * upsQuantity
  }

  static solveLosses(kwhPrice, opYears, cop, inflationPercent, ups) {
    // from year "0" to n
    const years = opYears + 1
    const inflation = inflationPercent / 100
    const totalLoad = this.normalize(ups.total_load, parseFloat)
    const upsQuantity = this.normalize(ups.quantity, parseInt)

    const efficiency = this.normalize(ups.efficiency_value, parseFloat) / 100

    const yearLosses = []
    let totalLosses = 0

    for (const year in [...Array(years).keys()]) {
      let yearLoss = 0

      if (year > 0) {
        const resValue = 1 + (1 / cop) * (1 + (year - 1) * inflation)

        yearLoss = 365.25 * 24 * totalLoad * (1 - efficiency) * kwhPrice * resValue * upsQuantity
      }

      yearLosses[year] = yearLoss

      totalLosses += yearLoss
    }

    return { data: yearLosses, total: totalLosses }
  }

  static solveContractsCost(ups, opYears, inflationPercent) {
    const years = opYears + 1
    const inflation = inflationPercent / 100
    const serviceCost = this.normalize(ups.contract_costs, parseInt)
    const upsQuantity = this.normalize(ups.quantity, parseInt)
    const serviceStartYear = this.normalize(ups.contract_starting_year, parseInt)

    const yearCosts = []
    let totalCosts = 0

    if (ups.contract_costs_enabled) {
      for (const year in [...Array(years).keys()]) {
        let yearCost = 0

        if (year > serviceStartYear - 1) {
          yearCost = serviceCost * (1 + year * inflation) * upsQuantity
        }

        yearCosts[year] = yearCost

        totalCosts += yearCost
      }
    }

    return { data: yearCosts, total: totalCosts }
  }

  static solveSparePartsCost(ups, opYears, inflationPercent) {
    const years = opYears + 1
    const inflation = inflationPercent / 100
    const upsQuantity = this.normalize(ups.quantity, parseInt)
    const yearCosts = []
    const subTotalCosts = []
    const cumulativeCosts = Array(opYears + 1).fill(0)
    let totalCosts = 0

    if (ups.spare_parts_enabled) {
      for (let partIndex = 0; partIndex < ups.spare_parts.length; partIndex++) {
        const part = ups.spare_parts[partIndex]
        const partCost = this.normalize(part.cost, parseFloat)
        const partYear = this.normalize(part.year, parseInt)
        const yearPartsCost = []
        let totalPartsCost = 0
        let subTotalPartsCost = 0

        for (const year in [...Array(years).keys()]) {
          let yearCost = 0

          if (year > 0 && year < opYears) {
            if (year % partYear === 0) {
              yearCost = partCost * (1 + year * inflation) * upsQuantity
            }
          }

          yearPartsCost[year] = yearCost

          totalPartsCost += yearCost

          subTotalPartsCost += yearCost

          cumulativeCosts[year] += yearCost
        }

        yearCosts[partIndex] = yearPartsCost
        totalCosts += totalPartsCost
        subTotalCosts[partIndex] = subTotalPartsCost
      }
    }

    return { data: yearCosts, total: totalCosts, subTotals: subTotalCosts, cumulativeTotals: cumulativeCosts }
  }

  static solveBatteryCost(ups, opYears, inflationPercent) {
    const years = opYears + 1
    const inflation = inflationPercent / 100
    const upsQuantity = this.normalize(ups.quantity, parseInt)
    const batteryCost = this.normalize(ups.replace_battery_cost, parseFloat)
    const batteryLife = this.normalize(ups.battery_life, parseInt)

    const yearCosts = []

    let totalCosts = 0

    if (ups.replace_battery_cost_enabled) {
      for (const year in [...Array(years).keys()]) {
        let yearCost = 0

        if (year > 0 && year < opYears) {
          if (year % batteryLife === 0) {
            yearCost = batteryCost * (1 + year * inflation) * upsQuantity
          }
        }

        yearCosts[year] = yearCost

        totalCosts += yearCost
      }
    }

    return { data: yearCosts, total: totalCosts }
  }

  static solveConsumablesCost(ups) {
    const upsQuantity = this.normalize(ups.quantity, parseInt)
    return ups.spare_parts_safety_stock_enabled ? this.normalize(ups.consumables * upsQuantity, parseFloat) : 0
  }

  static solvePowerExchange(ups, opYears) {
    const years = opYears + 1
    const powerExchange = this.normalize(ups.power_exchange, parseFloat)
    const upsQuantity = this.normalize(ups.quantity, parseInt)

    return { data: Array(years).fill(powerExchange, 1), total: powerExchange * years * upsQuantity }
  }

  static solveCumulativeCosts(opYears, product, losses, contract, battery, spareParts, isAuth, advancedConfig) {
    const years = opYears + 1

    return [...Array(years).keys()].map((year) => {
      if (year > 0) {
        let total = this.normalize(losses.data[year], parseFloat)

        if (advancedConfig) {
          total += this.normalize(contract.data[year], parseFloat)
          total += this.normalize(battery.data[year], parseFloat)
        }

        if (isAuth && advancedConfig) {
          total += this.normalize(spareParts.cumulativeTotals[year], parseFloat)
        }

        return Math.round(total)
      }

      return product
    })
  }
}

export default TCOMath
