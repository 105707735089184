import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

export default function CO2SavedTable({ totalLosses, selectedCountryCO2 }) {
  const { t } = useTranslation()
  const data = totalLosses.datasets[0].data
  const labels = totalLosses.labels
  const sortedData = data
    .map((value, index) => ({
      value,
      label: labels[index],
    }))
    .sort((a, b) => a.value - b.value)

  const calculateSavedCO2 = (totalKWhProduct2) => {
    if (selectedCountryCO2 && parseInt(selectedCountryCO2) !== 0) {
      const result = ((sortedData[0].value - totalKWhProduct2) * selectedCountryCO2) / 1000
      const positiveResult = Math.abs(result)
      return parseInt(positiveResult.toString().replace(/,/g, ""), 10)
    }
    return 0
  }

  return sortedData.length > 1 ? (
    <div>
      {sortedData.slice(1).map((dataPoint, dataPointIndex) => (
        <div key={`tab-value-${dataPointIndex}`} className="co2-table-cell" style={{ textAlign: "left" }}>
          {sortedData[0].label} {t("general.saves")} {calculateSavedCO2(dataPoint.value)} {t("general.saves_unit")}{" "}
          {dataPoint.label}
        </div>
      ))}
    </div>
  ) : null
}

CO2SavedTable.propTypes = {
  totalLosses: PropTypes.object,
  selectedCountryCO2: PropTypes.string,
}
