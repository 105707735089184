import { useState, useEffect, useCallback, useMemo } from "react"
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import NavbarComponent from "../components/NavbarComponent"
import GeneralInfoPanel from "../components/GeneralInfoPanel"
import UPSPanel from "../components/UPSPanel"
import { Constants } from "../util/Constants"
import GraphsPanel from "../components/GraphsPanel"
import { useTranslation } from "react-i18next"
import LoginPanel from "../components/LoginPanel"
import NavBarNoButtons from "../components/NavBarNoButtons"

export default function Home() {
  const { t, i18n } = useTranslation()

  // API data
  const [upsData, setUpsData] = useState([])

  // Language
  const [language, setLanguage] = useState(Constants.languages[0])

  // Selected UPS
  const [selectedUPS, setSelectedUPS] = useState([])
  const [advancedConfigs, setAdvancedConfigs] = useState(true)

  // General Info
  const [currency, setCurrency] = useState(Constants.currencyNames[Constants.currencies[0]])
  const [kwhPrice, setKwhPrice] = useState(Constants.GeneralInfoDefaultValues.kwhPrice)
  const [opYears, setOpYears] = useState(Constants.GeneralInfoDefaultValues.opYears)
  const [cop, setCop] = useState(Constants.GeneralInfoDefaultValues.cop)
  const [inflation, setInflation] = useState(Constants.GeneralInfoDefaultValues.inflation)

  // TCO calculation
  const [tcoList, setTCOList] = useState([])

  // Graph panel checkbox
  const [showTCO, setShowTCO] = useState(true)
  const [showCumulCosts, setShowCumulCosts] = useState(true)
  const [showTotCosts, setShowTotCosts] = useState(true)
  const [showTotLosses, setShowTotLosses] = useState(true)
  const [showCumulCostLine, setShowCumulCostLine] = useState(true)

  // Country - co2 data
  const [csvData, setCsvData] = useState([])
  const [selectedCountry, setSelectedCountry] = useState(null)
  const csvFilePath = "./carbon-intensity-electricity.csv"

  async function fetchCsvData() {
    try {
      const response = await fetch(csvFilePath)
      const text = await response.text()
      const rows = text.split("\n")
      const parsedData = {}

      for (let i = 1; i < rows.length; i++) {
        const row = rows[i]
        const columns = row.split(",")
        const country = columns[0]
        const code = columns[1]
        const co2 = columns[columns.length - 1].trim()

        if (code !== null && code !== "") {
          parsedData[country] = co2
        }
      }

      const dataArray = Object.keys(parsedData).map((country) => ({
        country,
        co2: parsedData[country],
      }))
      setCsvData(dataArray)
    } catch (error) {
      console.error("Error fetching CSV data:", error)
    }
  }

  useEffect(() => {
    fetchCsvData()
  }, [])

  const setLanguageAndChangeLanguage = useCallback(
    (language) => {
      setLanguage(language)
      i18n.changeLanguage(language)
    },
    [i18n]
  )

  const localStorageItems = useMemo(
    () => [
      { name: "language", setter: setLanguageAndChangeLanguage },
      { name: "selectedUPS", setter: setSelectedUPS },
      { name: "advancedConfigs", setter: setAdvancedConfigs },
      { name: "currency", setter: setCurrency },
      { name: "kwhPrice", setter: setKwhPrice },
      { name: "opYears", setter: setOpYears },
      { name: "cop", setter: setCop },
      { name: "inflation", setter: setInflation },
      { name: "tcoList", setter: setTCOList },
      { name: "showTCO", setter: setShowTCO },
      { name: "showCumulCosts", setter: setShowCumulCosts },
      { name: "showTotCosts", setter: setShowTotCosts },
      { name: "showTotLosses", setter: setShowTotLosses },
      { name: "showCumulCostLine", setter: setShowCumulCostLine },
      // TODO: See local storage, at the moment needs to be fixed
      // { name: "selectedCountry", setter: setSelectedCountry },
    ],
    [setLanguageAndChangeLanguage]
  )

  const loadSavedDataFromLocalStorage = useCallback(() => {
    localStorageItems.forEach((item) => {
      handleLocalStorageItem(item.name, item.setter)
    })
  }, [localStorageItems])

  const callAPI = useCallback(async () => {
    // fetching API
    const response = await fetch(`${process.env.REACT_APP_API_URL}/AvailableUPS`)
    const upsJson = await response.json()
    // add custom ups
    upsJson.push({
      ...Constants.UPSDefaultValues,
      name: t("select_ups.custom_ups"),
      spare_parts: [],
      contract_costs_enabled: false,
      replace_battery_cost_enabled: false,
      spare_parts_safety_stock_enabled: false,
      spare_parts_enabled: false,
      is_custom: true,
      rated_power: 0,
    })
    setUpsData(upsJson)
    setSelectedUPS((aSelectedUPS) =>
      aSelectedUPS.length === 0
        ? [
            {
              ...Constants.UPSDefaultValues,
              ...upsJson[0],
              spare_parts: [],
              contract_costs_enabled: false,
              replace_battery_cost_enabled: false,
              spare_parts_safety_stock_enabled: false,
              spare_parts_enabled: false,
              is_custom: false,
            },
          ]
        : aSelectedUPS
    )
  }, [t])

  useEffect(() => {
    callAPI()
    loadSavedDataFromLocalStorage()
  }, [callAPI, loadSavedDataFromLocalStorage])

  const saveDataToLocalStorage = () => {
    localStorage.setItem("language", JSON.stringify(language))
    localStorage.setItem("selectedUPS", JSON.stringify(selectedUPS))
    localStorage.setItem("advancedConfigs", JSON.stringify(advancedConfigs))
    localStorage.setItem("currency", JSON.stringify(currency))
    localStorage.setItem("kwhPrice", JSON.stringify(kwhPrice))
    localStorage.setItem("opYears", JSON.stringify(opYears))
    localStorage.setItem("cop", JSON.stringify(cop))
    localStorage.setItem("inflation", JSON.stringify(inflation))
    localStorage.setItem("tcoList", JSON.stringify(tcoList))
    localStorage.setItem("showTCO", JSON.stringify(showTCO))
    localStorage.setItem("showCumulCosts", JSON.stringify(showCumulCosts))
    localStorage.setItem("showTotCosts", JSON.stringify(showTotCosts))
    localStorage.setItem("showTotLosses", JSON.stringify(showTotLosses))
    localStorage.setItem("showCumulCostLine", JSON.stringify(showCumulCostLine))
    // TODO: See local storage, at the moment needs to be fixed
    // localStorage.setItem("selectedCountry", JSON.stringify(selectedCountry))
  }

  const handleLocalStorageItem = (itemName, setter) => {
    try {
      const item = localStorage.getItem(itemName)
      if (item !== null) {
        const parsedItem = JSON.parse(item)
        setter(parsedItem)
        localStorage.removeItem(itemName)
      }
    } catch (error) {
      console.error(`Error handling ${itemName}:`, error)
    }
  }

  return (
    <>
      <AuthenticatedTemplate>
        <NavbarComponent
          language={language}
          setLanguage={setLanguage}
          selectedUPS={selectedUPS}
          tcoList={tcoList}
          saveDataToLocalStorage={saveDataToLocalStorage}
          selectedCountryCO2={
            selectedCountry !== undefined ? csvData.find((item) => item.country === selectedCountry)?.co2 : 0
          }
        />
        <Container style={{ marginTop: 25 }} fluid>
          <Col className="general-info-section">
            <GeneralInfoPanel
              currency={currency}
              setCurrency={setCurrency}
              kwhPrice={kwhPrice}
              setKwhPrice={setKwhPrice}
              opYears={opYears}
              setOpYears={setOpYears}
              cop={cop}
              setCop={setCop}
              inflation={inflation}
              setInflation={setInflation}
              csvData={csvData}
              setSelectedCountry={setSelectedCountry}
            />
          </Col>
          <Col className="ups-section">
            <UPSPanel
              upsData={upsData}
              setSelectedUPS={setSelectedUPS}
              selectedUPS={selectedUPS}
              currency={currency}
              advancedConfigs={advancedConfigs}
              setAdvancedConfigs={setAdvancedConfigs}
              kwhPrice={kwhPrice}
              opYears={opYears}
              cop={cop}
              inflation={inflation}
              advancedConfig={advancedConfigs}
              setTCOList={setTCOList}
              selectedCountryCO2={
                selectedCountry !== undefined ? csvData.find((item) => item.country === selectedCountry)?.co2 : 0
              }
            />
          </Col>
          <Col className="calculations-section">
            {selectedUPS.length > 0 ? (
              <GraphsPanel
                currency={currency}
                selectedUPS={selectedUPS}
                kwhPrice={kwhPrice}
                opYears={opYears}
                cop={cop}
                inflation={inflation}
                advancedConfig={advancedConfigs}
                setTCOList={setTCOList}
                showTotCosts={showTotCosts}
                setShowTotCosts={setShowTotCosts}
                showCumulCosts={showCumulCosts}
                setShowCumulCosts={setShowCumulCosts}
                showTotLosses={showTotLosses}
                setShowTotLosses={setShowTotLosses}
                showCumulCostLine={showCumulCostLine}
                setShowCumulCostLine={setShowCumulCostLine}
              />
            ) : null}
          </Col>
        </Container>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <NavBarNoButtons></NavBarNoButtons>
        <LoginPanel></LoginPanel>
      </UnauthenticatedTemplate>
    </>
  )
}
