import { useState, useEffect } from "react"
import { AuthenticatedTemplate, useIsAuthenticated } from "@azure/msal-react"
import { Doughnut } from "react-chartjs-2"
import PropTypes from "prop-types"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Tab from "react-bootstrap/Tab"
import Tabs from "react-bootstrap/Tabs"
import Form from "react-bootstrap/Form"
import InputGroup from "react-bootstrap/InputGroup"
import Button from "react-bootstrap/Button"
import CloseButton from "react-bootstrap/CloseButton"
import Table from "react-bootstrap/Table"
import { Constants } from "../util/Constants"
import { useTranslation } from "react-i18next"
import { hasPowerExchange } from "../util/Utility.js"
import GraphsBuilder from "../util/builders/GraphsBuilder"
import CO2SavedTable from "./CO2SavedTable.jsx"

export default function UPSPanel({
  upsData,
  selectedUPS,
  setSelectedUPS,
  currency,
  advancedConfigs,
  setAdvancedConfigs,
  kwhPrice,
  opYears,
  cop,
  inflation,
  setTCOList,
  selectedCountryCO2,
}) {
  const [currentTabKey, setCurrentTabKey] = useState(0)
  const [currentBoolTabKey, setCurrentBoolTabKey] = useState([true])
  const [hasCustom, setHasCustom] = useState(false)
  const [tcoData, setTCOData] = useState([{ labels: [], datasets: [] }])
  const [totLossesData, setTotLossesData] = useState({ labels: [], datasets: [] })

  const { t, i18n } = useTranslation()
  const isAuth = useIsAuthenticated()

  useEffect(() => {
    const builder = new GraphsBuilder(
      t,
      Constants,
      isAuth,
      advancedConfigs,
      selectedUPS,
      kwhPrice,
      opYears,
      cop,
      inflation
    )
    setTotLossesData(builder.buildTotalLosses())
    setTCOData(builder.buildTCO(advancedConfigs))
    setTCOList(builder.tcoList)
  }, [selectedUPS, kwhPrice, opYears, cop, inflation, i18n.language, isAuth, advancedConfigs, t, setTCOList])

  const customizeUPS = (upsId, attribute, value) => {
    let updatedUPS = null
    setSelectedUPS(
      selectedUPS.map((ups, upsIndex) => {
        if (upsIndex === upsId) {
          // Create a *new* object with changes
          updatedUPS = { ...ups, [attribute]: value }
          if (!ups.is_custom) {
            updatedUPS.efficiency_value = solveEfficiency(
              updatedUPS.efficiency,
              updatedUPS.total_load,
              updatedUPS.rated_power,
              updatedUPS.ratedActivePowers
            )
          }
          return updatedUPS
        } else {
          // No changes
          return ups
        }
      })
    )
  }

  const updateTotalLoadValues = (value) => {
    setSelectedUPS(
      selectedUPS.map((ups) => {
        // Update the total load and efficiency of each UPS
        const updatedUPS = { ...ups, total_load: value }
        updatedUPS.efficiency_value = solveEfficiency(
          updatedUPS.efficiency,
          updatedUPS.total_load,
          updatedUPS.rated_power,
          updatedUPS.ratedActivePowers
        )
        return updatedUPS
      })
    )
  }

  const validateAttribute = (ups) => {
    if (!ups.is_custom) {
      let ratedPower = ups.rated_power

      if (ups.rated_power === undefined) {
        // use the default selected rated_power (first element of array)
        ratedPower = ups.ratedActivePowers[0]
      }

      const loadPercent = ups.total_load / ratedPower

      if (loadPercent >= 0.2 && loadPercent <= 1.0) {
        return false
      }
    }
    return true
  }

  const addUPS = () => {
    const ups1TotalLoad = selectedUPS.length > 0 ? selectedUPS[0].total_load : Constants.UPSDefaultValues.total_load
    setSelectedUPS([
      ...selectedUPS,
      {
        ...Constants.UPSDefaultValues,
        ...upsData[0],
        total_load: ups1TotalLoad,
        spare_parts: [],
        contract_costs_enabled: false,
        replace_battery_cost_enabled: false,
        spare_parts_safety_stock_enabled: false,
        spare_parts_enabled: false,
        is_custom: false,
        efficiency_value: solveEfficiency(
          upsData[0].efficiency,
          ups1TotalLoad,
          upsData[0].rated_power,
          upsData[0].ratedActivePowers
        ),
      },
    ])
  }

  const addCustomUPS = (upsId) => {
    const updatedTotalLoad =
      upsId > 0 && selectedUPS.length > 0 ? selectedUPS[0].total_load : Constants.UPSDefaultValues.total_load
    setSelectedUPS(
      selectedUPS.map((ups, upsIndex) => {
        if (upsIndex === upsId) {
          const updatedUps = {
            ...Constants.UPSDefaultValues,
            spare_parts: [],
            contract_costs_enabled: false,
            replace_battery_cost_enabled: false,
            spare_parts_safety_stock_enabled: false,
            spare_parts_enabled: false,
            is_custom: true,
            rated_power: 0,
            name: t("general.custom_ups_name")
          }
          updatedUps.total_load = updatedTotalLoad
          return updatedUps
        } else {
          return { ...ups, total_load: updatedTotalLoad }
        }
      })
    )
  }

  const setProduct = (upsId, upsValues) => {
    const newUPS = JSON.parse(upsValues)

    if (newUPS.is_custom) {
      addCustomUPS(upsId)
      setHasCustom(true)
    } else {
      newUPS.is_custom = false
      let updatedUPS = null

      const updatedTotalLoad =
        upsId > 0 && selectedUPS.length > 0 ? selectedUPS[0].total_load : Constants.UPSDefaultValues.total_load
      setSelectedUPS(
        selectedUPS.map((ups, upsIndex) => {
          if (upsIndex === upsId) {
            if (ups.is_custom) {
              setHasCustom(false)
            }
            // Overwrite all the common (base) attributes
            updatedUPS = {
              ...Constants.UPSDefaultValues,
              ...newUPS,
              spare_parts: [],
              contract_costs_enabled: false,
              replace_battery_cost_enabled: false,
              spare_parts_safety_stock_enabled: false,
              spare_parts_enabled: false,
              is_custom: false,
            }
            updatedUPS.total_load = updatedTotalLoad
          } else {
            // Update total load for all other UPSs
            updatedUPS = { ...ups, total_load: updatedTotalLoad }
          }
          updatedUPS.efficiency_value = solveEfficiency(
            updatedUPS.efficiency,
            updatedUPS.total_load,
            updatedUPS.rated_power,
            updatedUPS.ratedActivePowers
          )
          return updatedUPS
        })
      )
    }
  }

  const removeTab = (upsId, event, isCustom) => {
    event.stopPropagation()

    setSelectedUPS((prevState) => prevState.filter((ups, upsIndex) => upsIndex !== upsId))

    if (isCustom) {
      setHasCustom(false)
    }

    setCurrentTabKey(0)

    const newBooleanArray = Array(tcoData.length).fill(false)
    newBooleanArray[0] = true
    setCurrentBoolTabKey(newBooleanArray)
  }

  const updateSparePart = (value, attribute, partId, upsId) => {
    setSelectedUPS(
      selectedUPS.map((ups, upsIndex) => {
        if (upsIndex === upsId) {
          // Overwrite spare part attribute
          ups.spare_parts[partId][attribute] = value
        }
        return ups
      })
    )
  }

  const removeSparePart = (partId, upsId) => {
    setSelectedUPS(
      selectedUPS.map((ups, upsIndex) => {
        if (upsIndex === upsId) {
          // Remove spare part from array
          ups.spare_parts.splice(partId, 1)
        }
        return ups
      })
    )
  }

  const addSparePart = (upsId) => {
    setSelectedUPS(
      selectedUPS.map((ups, upsIndex) => {
        if (upsIndex === upsId) {
          // Add new spare part
          ups.spare_parts.push({ ...Constants.SparePartDefaultValues })
        }
        return ups
      })
    )
  }

  const solveEfficiency = (efficiencyMap, totalLoad, ratedPower, powersList) => {
    if (ratedPower === undefined) {
      // use the default selected rated_power (first element of array)
      ratedPower = powersList[0]
    }

    const loadPercent = totalLoad / ratedPower

    if (loadPercent < 0.2 || loadPercent > 1.0 || totalLoad === undefined || efficiencyMap === undefined) {
      // invalid load percentage!
      return "-"
    }

    const dict = {
      0.05: efficiencyMap.efficiencyAt5,
      0.25: efficiencyMap.efficiencyAt25,
      0.5: efficiencyMap.efficiencyAt50,
      0.75: efficiencyMap.efficiencyAt75,
      1.0: efficiencyMap.efficiencyAt100,
    }

    if (loadPercent in dict) {
      return dict[loadPercent] * 100
    } else {
      const keys = Object.keys(dict).map(Number)
      const x = keys.filter((key) => key < loadPercent).sort((a, b) => b - a)[0]
      const y = keys.filter((key) => key > loadPercent).sort((a, b) => a - b)[0]
      const x1 = dict[x]
      const y1 = dict[y]
      const efficiency = (x1 + (loadPercent - x) * ((y1 - x1) / (y - x))) * 100
      return Math.round(efficiency * 10) / 10
    }
  }

  const TCODonutChartOptions = (titleString) => {
    return {
      plugins: {
        title: {
          display: true,
          text: titleString,
          padding: {
            top: 10,
            bottom: 10,
          },
        },
        legend: {
          display: true,
          position: "right",
        },
        tooltip: {
          callbacks: {
            label: (ttItem) => `${ttItem.parsed}%`,
          },
        },
      },
      maintainAspectRatio: false,
      aspectRatio: 1.5,
      responsive: true,
    }
  }

  const handleTabSelect = (key) => {
    setCurrentTabKey(key)
    const newBooleanArray = Array(tcoData.length).fill(false)
    newBooleanArray[key] = true
    setCurrentBoolTabKey(newBooleanArray)
  }

  return (
    <Container style={{ padding: 55 }}>
      <Row className="text-center">
        <Col>
          <img src="icons/sections/sep.png" width="40" height="5" />
        </Col>
      </Row>
      <p id="ups-title" className="text-center ups-section-title">
        {t("select_ups.title")}
      </p>
      <Row className="ups-section-row">
        <Col xs={{ order: "first" }}>
          <Form>
            <Form.Check
              type="checkbox"
              id="advanced-checkbox"
              label={t("select_ups.advanced_conf")}
              checked={advancedConfigs}
              onChange={() => {
                setAdvancedConfigs(!advancedConfigs)
              }}
            />
          </Form>
        </Col>
        <Col xs={{ order: "last" }} style={{ flexDirection: "row" }}>
          <Button
            variant="primary"
            style={{ marginTop: 5 }}
            className="float-end"
            onClick={addUPS}
            disabled={selectedUPS.length > 5}
          >
            {t("select_ups.add_ups")}
          </Button>
        </Col>
      </Row>

      <Row>
        <Col>
          <Tabs id="ups-tabs" activeKey={currentTabKey} onSelect={handleTabSelect} className="mb-3">
            {selectedUPS.map((tab, tIndex) => {
              const isValidLoad = validateAttribute(tab)
              return (
                <Tab
                  key={tIndex}
                  eventKey={tIndex}
                  title={
                    <div className="ups-tab-span row">
                      {`${t("general.ups")} ${tIndex + 1}`}
                      <CloseButton
                        onClick={(event) => {
                          removeTab(tIndex, event, tab.is_custom)
                        }}
                        className="ups-tab-button"
                        disabled={selectedUPS.length <= 1}
                      />
                    </div>
                  }
                >
                  <p className="ups-section-title">{tab.name}</p>
                  <Row>
                    <Col>
                      <p className="ups-input-title">{t("select_ups.quantity")}</p>
                      <Form.Control
                        className="ups-input"
                        id={`quantity-input-${tIndex}`}
                        type="number"
                        value={tab.quantity}
                        onChange={(event) => {
                          customizeUPS(tIndex, "quantity", event.target.value)
                        }}
                      />
                      <p className="ups-input-title">{t("select_ups.product")}</p>
                      <Form.Select
                        className="ups-input"
                        onChange={(event) => {
                          setProduct(tIndex, event.target.value)
                        }}
                      >
                        {upsData.map((ups, index) => {
                          return (
                            <option
                              disabled={ups.is_custom && hasCustom}
                              key={JSON.stringify(index)}
                              value={JSON.stringify(ups)}
                            >
                              {ups.name}
                            </option>
                          )
                        })}
                      </Form.Select>
                      <div style={{ marginTop: 10, padding: 20, maxWidth: "272px", border: "1px solid #ccc" }}>
                        <img
                          id={`ups-img-${tIndex}`}
                          src={tab.is_custom ? "./icons/ups/custom.jpg" : `https:${tab.image}`}
                          style={{ maxWidth: "272px", width: "100%", maxHeight: "206px", objectFit: "contain" }}
                        />
                      </div>
                    </Col>
                    <Col>
                      <p className="ups-input-title">{t("select_ups.power_unit")}</p>
                      <Form.Control
                        disabled
                        readOnly
                        className="ups-const"
                        id={`power-unit-const-${tIndex}`}
                        value="kW"
                      />
                      <p className="ups-input-title">{t("select_ups.power_rating")}</p>
                      {tab.is_custom ? (
                        <Form.Control
                          id={`rated-power-${tIndex}`}
                          className="ups-input"
                          type="number"
                          value={tab.rated_power}
                          onChange={(event) => customizeUPS(tIndex, "rated_power", event.target.value)}
                        ></Form.Control>
                      ) : (
                        <Form.Select
                          id={`rated-power-${tIndex}`}
                          className="ups-input"
                          onChange={(event) => customizeUPS(tIndex, "rated_power", event.target.value)}
                        >
                          {tab.ratedActivePowers.map((pow) => (
                            <option key={pow} value={pow}>
                              {pow}
                            </option>
                          ))}
                        </Form.Select>
                      )}
                      <p className="ups-input-title">{t("select_ups.ups_price")}</p>
                      <InputGroup className="ups-input">
                        <Form.Control
                          className="ups-input"
                          id={`unit-price-input-${tIndex}`}
                          type="number"
                          value={tab.unit_price}
                          onChange={(event) => {
                            customizeUPS(tIndex, "unit_price", event.target.value)
                          }}
                        />
                        <InputGroup.Text>{currency}</InputGroup.Text>
                      </InputGroup>

                      <p className="ups-input-title">{t("select_ups.tot_load")}</p>
                      {tab.is_custom ? (
                        <InputGroup className="ups-input">
                          <Form.Control
                            id={`tot-load-input-${tIndex}`}
                            className="ups-input"
                            type="number"
                            value={tab.total_load}
                            onChange={(event) => {
                              customizeUPS(tIndex, "total_load", event.target.value)
                            }}
                            disabled={tIndex !== 0}
                          ></Form.Control>
                          <InputGroup.Text>kW</InputGroup.Text>
                        </InputGroup>
                      ) : (
                        <InputGroup className="ups-input">
                          <Form.Control
                            className="ups-input"
                            id={`tot-load-input-${tIndex}`}
                            type="number"
                            value={tab.total_load}
                            onChange={(event) => {
                              updateTotalLoadValues(event.target.value)
                            }}
                            disabled={tIndex !== 0}
                            isInvalid={isValidLoad}
                          />
                          <Form.Control.Feedback type="invalid">{t("general.invalid_load")}</Form.Control.Feedback>
                          {isValidLoad ? null : <InputGroup.Text>kW</InputGroup.Text>}
                        </InputGroup>
                      )}
                      <p className="ups-input-title">{t("select_ups.efficiency")}</p>
                      {tab.is_custom ? (
                        <InputGroup className="ups-input">
                          <Form.Control
                            id={`efficiency-${tIndex}`}
                            className="ups-input"
                            type="number"
                            value={tab.efficiency_value}
                            onChange={(event) => customizeUPS(tIndex, "efficiency_value", event.target.value)}
                          ></Form.Control>
                          <InputGroup.Text>%</InputGroup.Text>
                        </InputGroup>
                      ) : (
                        <Form.Control
                          disabled
                          className="ups-const"
                          id={`efficiency-${tIndex}`}
                          value={
                            typeof tab.efficiency_value === "number" && !isNaN(tab.efficiency_value)
                              ? parseFloat(tab.efficiency_value).toFixed(1) + "%"
                              : tab.efficiency_value
                          }
                        />
                      )}
                    </Col>
                  </Row>
                  <Tabs style={{ marginTop: 15, marginBottom: 15 }} />
                  {advancedConfigs ? (
                    <>
                      <p className="ups-subsection-title">{t("select_ups.yearly_unit_costs")}</p>
                      <Row>
                        <Col>
                          <Form>
                            <Form.Check
                              type="checkbox"
                              id={`replace-battery-enabled-cost-${tIndex}`}
                              label={t("select_ups.replace_battery_cost")}
                              className="ups-checkbox-title"
                              checked={tab.replace_battery_cost_enabled}
                              onChange={() => {
                                customizeUPS(tIndex, "replace_battery_cost_enabled", !tab.replace_battery_cost_enabled)
                              }}
                            />
                          </Form>
                          <Form.Control
                            className="ups-input"
                            id={`replace-battery-cost-${tIndex}`}
                            type="number"
                            value={tab.replace_battery_cost}
                            onChange={(event) => {
                              customizeUPS(tIndex, "replace_battery_cost", event.target.value)
                            }}
                            disabled={!tab.replace_battery_cost_enabled}
                          />
                          <p className="ups-input-title">{t("select_ups.battery_life_time")}</p>
                          <Form.Control
                            className="ups-input"
                            id={`battery-life-${tIndex}`}
                            type="number"
                            value={tab.battery_life}
                            onChange={(event) => {
                              customizeUPS(tIndex, "battery_life", event.target.value)
                            }}
                            disabled={!tab.replace_battery_cost_enabled}
                          />
                        </Col>
                        <Col>
                          <Form>
                            <Form.Check
                              type="checkbox"
                              id={`contr-enabled-costs-${tIndex}`}
                              label={t("select_ups.service_contr_costs")}
                              className="ups-checkbox-title"
                              checked={tab.contract_costs_enabled}
                              onChange={() => {
                                customizeUPS(tIndex, "contract_costs_enabled", !tab.contract_costs_enabled)
                              }}
                            />
                          </Form>
                          <Form.Control
                            className="ups-input"
                            id={`contr-costs-${tIndex}`}
                            type="number"
                            value={tab.contract_costs}
                            onChange={(event) => {
                              customizeUPS(tIndex, "contract_costs", event.target.value)
                            }}
                            disabled={!tab.contract_costs_enabled}
                          />
                        </Col>
                      </Row>
                      <Tabs style={{ marginTop: 25, marginBottom: 15 }} />
                      <AuthenticatedTemplate>
                        <p className="ups-subsection-title">{t("select_ups.further_details")}</p>
                        <Row>
                          <Col>
                            <Form>
                              <Form.Check
                                type="checkbox"
                                id={`spare-parts-safety-stock-enabled-${tIndex}`}
                                label={t("select_ups.consumables")}
                                className="ups-checkbox-title"
                                checked={tab.spare_parts_safety_stock_enabled}
                                onChange={() => {
                                  customizeUPS(
                                    tIndex,
                                    "spare_parts_safety_stock_enabled",
                                    !tab.spare_parts_safety_stock_enabled
                                  )
                                }}
                              />
                            </Form>
                            <Form.Control
                              className="ups-input"
                              id={`consumables-input-${tIndex}`}
                              type="number"
                              value={tab.consumables}
                              onChange={(event) => {
                                customizeUPS(tIndex, "consumables", event.target.value)
                              }}
                              disabled={!tab.spare_parts_safety_stock_enabled}
                            />
                          </Col>
                          <Col>
                            {/* <p className="ups-input-title">{t("select_ups.co2")}</p>
                        <Form.Control disabled readOnly className="ups-const" id="co2-const" value={`${tab.co2} ppm`} /> */}
                            <p className="ups-input-title" hidden={!hasPowerExchange(selectedUPS[tIndex])}>
                              {t("select_ups.power_exchange")}
                            </p>
                            <Form.Control
                              className="ups-input"
                              id={`power-exchange-input-${tIndex}`}
                              type="number"
                              value={tab.power_exchange}
                              onChange={(event) => {
                                customizeUPS(tIndex, "power_exchange", event.target.value)
                              }}
                              hidden={!hasPowerExchange(selectedUPS[tIndex])}
                            />
                          </Col>
                        </Row>
                        <Tabs style={{ marginTop: 25, marginBottom: 15 }} />
                        <Form>
                          <Form.Check
                            type="checkbox"
                            id={`spare-parts-enabled-cost-${tIndex}`}
                            label={t("select_ups.spare_parts_list")}
                            className="ups-checkbox-title"
                            checked={tab.spare_parts_enabled}
                            onChange={() => {
                              customizeUPS(tIndex, "spare_parts_enabled", !tab.spare_parts_enabled)
                            }}
                          />
                        </Form>
                        {tab.spare_parts_enabled ? (
                          <>
                            <Table style={{ marginTop: 20 }}>
                              <thead>
                                <tr>
                                  <th className="ups-input-title">{t("select_ups.spare_product_name")}</th>
                                  <th className="ups-input-title">{t("select_ups.spare_product_year")}</th>
                                  <th className="ups-input-title">{t("select_ups.spare_product_cost")}</th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                {tab.spare_parts.map((part, index) => {
                                  return (
                                    <tr key={index}>
                                      <td>
                                        <input
                                          value={part.name}
                                          type="text"
                                          onChange={(e) => updateSparePart(e.target.value, "name", index, tIndex)}
                                          style={{ border: "0" }}
                                        />
                                      </td>
                                      <td>
                                        <input
                                          value={part.year}
                                          type="number"
                                          onChange={(e) => updateSparePart(e.target.value, "year", index, tIndex)}
                                          style={{ maxWidth: 50, border: "0" }}
                                        />
                                      </td>
                                      <td>
                                        <input
                                          value={part.cost}
                                          type="number"
                                          onChange={(e) => updateSparePart(e.target.value, "cost", index, tIndex)}
                                          style={{ maxWidth: 50, border: "0" }}
                                        />
                                      </td>
                                      <td>
                                        <img
                                          src="icons/sections/bin.png"
                                          width="12"
                                          height="14"
                                          onClick={() => removeSparePart(index, tIndex)}
                                        />
                                      </td>
                                    </tr>
                                  )
                                })}
                              </tbody>
                            </Table>
                            <Button
                              variant="primary"
                              onClick={() => {
                                addSparePart(tIndex)
                              }}
                            >
                              {t("select_ups.spare_product_add")}
                            </Button>
                          </>
                        ) : null}
                      </AuthenticatedTemplate>
                    </>
                  ) : null}
                </Tab>
              )
            })}
          </Tabs>
        </Col>
        <Col>
          <div style={{ marginTop: 45, paddingLeft: "8%" }}>
            {tcoData.map((ups, upsIndex) => (
              <div
                key={upsIndex}
                style={{
                  width: "100%",
                  border: "2px solid #ccc",
                  padding: "12px 20px 12px 20px",
                  marginBottom: "20px",
                  opacity: currentBoolTabKey[upsIndex] ? 1 : 0.3,
                  minHeight: "300px",
                }}
              >
                <Doughnut
                  key={upsIndex}
                  id={`tco_${upsIndex + 1}`}
                  data={ups}
                  options={TCODonutChartOptions(
                    selectedUPS[upsIndex] && selectedUPS[upsIndex].name
                      ? selectedUPS[upsIndex].name
                      : `${t("general.ups")} ${upsIndex + 1}`
                  )}
                  style={{ width: "100%", height: "100%" }}
                />
              </div>
            ))}
          </div>
          {tcoData.length !== 1 && (
            <div style={{ marginTop: 45, paddingLeft: "8%" }}>
              <p className="ups-subsection-title">{t("select_ups.co2_saved")}</p>
              <CO2SavedTable totalLosses={totLossesData} selectedCountryCO2={selectedCountryCO2}></CO2SavedTable>
            </div>
          )}
        </Col>
      </Row>
    </Container>
  )
}

UPSPanel.propTypes = {
  upsData: PropTypes.array,
  selectedUPS: PropTypes.array,
  setSelectedUPS: PropTypes.func,
  setAdvancedConfigs: PropTypes.func,
  advancedConfigs: PropTypes.bool,
  currency: PropTypes.string,
  kwhPrice: PropTypes.string,
  opYears: PropTypes.string,
  cop: PropTypes.string,
  inflation: PropTypes.string,
  setTCOList: PropTypes.func,
  selectedCountryCO2: PropTypes.string,
}
