import { useState } from "react"
import PropTypes from "prop-types"
import Dropdown from "react-bootstrap/Dropdown"
import Form from "react-bootstrap/Form"
import { useTranslation } from "react-i18next"
import { InputGroup } from "react-bootstrap"
export default function CountryDropdown({ csvData, setSelectedCountry }) {
  const [inputValue, setInputValue] = useState()
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const { t } = useTranslation()

  const filteredData =
    inputValue !== undefined
      ? csvData.filter((item) => item.country.toLowerCase().startsWith(inputValue.toLowerCase()))
      : undefined

  const handleInputChange = (e) => {
    const inputValue = e.target.value
    if (inputValue.trim().length === 0) {
      setSelectedCountry(undefined)
    }
    setInputValue(inputValue)
    setDropdownOpen(inputValue.length > 0)
  }

  const handleDropdownItemClick = (country) => {
    setInputValue(country)
    setSelectedCountry(country)
    setDropdownOpen(false)
  }

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen)
  }

  const isValid =
    dropdownOpen ||
    (inputValue !== undefined &&
      filteredData.filter((item) => item.country.toLowerCase() === inputValue.toLowerCase()).length === 1)

  return (
    <Dropdown show={dropdownOpen} onToggle={toggleDropdown}>
      <InputGroup className="ups-input">
        <Form.Control
          id="dropdown-input"
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          placeholder={t("general_info.type_to_filter")}
          isInvalid={!isValid}
        />
        <Form.Control.Feedback type="invalid">{t("general.invalid_country")}</Form.Control.Feedback>
      </InputGroup>
      <Dropdown.Menu>
        {filteredData !== undefined
          ? filteredData.map((item, index) => (
              <Dropdown.Item
                key={`country-sel-${index}`}
                onClick={() => handleDropdownItemClick(item.country)}
                href="#"
              >
                {item.country}
              </Dropdown.Item>
            ))
          : null}
      </Dropdown.Menu>
    </Dropdown>
  )
}

CountryDropdown.propTypes = {
  csvData: PropTypes.array,
  setSelectedCountry: PropTypes.func.isRequired,
}
