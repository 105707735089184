// LoginPanel.js
import "./style/LoginPanel.css"
import { useMsal } from "@azure/msal-react"
import Button from "react-bootstrap/Button"

const LoginPanel = () => {
  const { instance } = useMsal()
  const handleLogin = () => {
    instance.loginRedirect()
  }

  return (
    <div className="login-container">
      <div className="login-box-panel">
        <label>Login with your MyABB account</label>
        <div className="login-button-container">
          <Button className="login-button" onClick={handleLogin}>
            Login
          </Button>
        </div>
      </div>
    </div>
  )
}

export default LoginPanel
