import "bootstrap/dist/css/bootstrap.min.css"
import "./App.css"
import Home from "./pages/Home"
import { PublicClientApplication } from "@azure/msal-browser"
import { MsalProvider } from "@azure/msal-react"
import { Suspense } from "react"

// ADD login config
const config = {
  auth: {
    clientId: process.env.REACT_APP_AAD_CLIENT_ID,
    authority: process.env.REACT_APP_AAD_AUTHORITY
  }
}

// create PublicClientApplication instance
const publicClientApplication = new PublicClientApplication(config)

function App() {
  return (
    <>
      <MsalProvider instance={publicClientApplication}>
        <Home />
      </MsalProvider>
    </>
  )
}

export default function WrappedApp() {
  return (
    <Suspense fallback="...loading">
      <App />
    </Suspense>
  )
}