import { useTranslation } from "react-i18next"

import Navbar from "react-bootstrap/Navbar"

export default function NavBarNotButtons() {
  const { t } = useTranslation()

  return (
    <Navbar className="navbar-home" fixed="top">
      <Navbar.Brand href="/">
        <img id="logo" src="icons/navbar/logo.svg" width="78" height="30" className="d-inline-block align-top" />
      </Navbar.Brand>
      <p className="app-title">{t("general.title")}</p>
    </Navbar>
  )
}
